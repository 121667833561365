import { useEffect, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import { Link, useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './../../assets/css/ProductPage.css'
import { FaShoppingCart } from "react-icons/fa";

const NewProduct = (components) => {
    const navigate = useNavigate();
    const [allProducts, setAllProducts] = useState(components.allProducts);

    useEffect(() => {
        setAllProducts(components.allProducts);
    }, [components])

    const options = {
        items: 3,
        margin: 10,
        loop: true,
        nav: true,
        dots: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true
    }

    return (
        <>
            <div className="container margin_60_35">
                {/* <div className="main_title">
                    <h2>สินค้าใหม่</h2>
                    <span>สินค้าใหม่</span>
                </div> */}
                <div style={
                    {
                        padding: "32px",
                        borderRadius: "7px 65px",
                        backgroundImage: "linear-gradient(90deg, #3236a4 10%, #6bbcea 90%)",
                        color: "white",
                        fontSize: "25px"
                    }
                } className="mb-3">
                    สินค้าใหม่
                </div>
                {
                    allProducts.length > 0 ? <OwlCarousel
                        {...options}
                    >
                    {
                        allProducts.slice(0,7).map((product, index) => {
                            return (
                                <div className="item" key={index}>
                                    {/* <div className="grid_item">
                                        <figure>
                                            <Link to={`/goto/${product.id}`}>
                                                <img
                                                    className="owl-lazy"
                                                    src={product.path != null ? product.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                    data-src={product.path != null ? product.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                    alt=""
                                                />
                                            </Link>
                                        </figure>
                                        <Link to={`/goto/${product.id}`}>
                                            <h3>{product.name}</h3>
                                        </Link>
                                        <div className="price_box">
                                            <span className="new_price">{parseFloat(product.price).toLocaleString("en-US")}฿</span>
                                        </div>
                                    </div> */}
                                    <div className="grid_item">   

                                        {/* รูป */}
                                        <img src={product.path != null ? product.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`} alt={product.name.slice(0, 20)} />

                                        {/* {ชื่อสินค้า} */}
                                        <p>
                                            <Link to={`/products/${product.id}`}>
                                                {product.name}
                                            </Link>
                                        </p>

                                        {/* รายละเอียดสินค้า */}
                                        {/* <p>{product.description}</p> */}

                                        {/* ราคาสินค้า */}
                                        <p>
                                            ราคาเริ่มต้น: {
                                                product.price != null ? parseFloat(product.price).toLocaleString("en-US") : parseFloat(0.00).toLocaleString("en-US")
                                            }฿
                                        </p>

                                        {/* ราคาที่ลดแล้ว */}
                                        {/* <p>สมาชิก $ {(item.price - (item.price * Discount / 100)).toFixed(2)} <span>-{Discount}%</span>  </p> */}


                                            <Link to={`/products/${product.id}`} className="button"><FaShoppingCart/> สั่งซื้อสินค้า</Link>

                                        {/* <p><small>(มีหลายคุณสมบัติให้เลือก)</small></p> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                    </OwlCarousel> : <Skeleton count={10} />
                }
                
            </div>
            {/* /container */}
        </>
    )
}

export default NewProduct;