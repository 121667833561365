import './../../../assets/css/listing.css'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const AllProducts = (components) => {
    const perPage = 15;
    const [allProducts, setAllProducts] = useState(components.allProducts);
    const [wishlisted, setWishlisted] = useState(components.wishlist);
    const [categories, setCategories] = useState(components.categories);
    const [checkedCategories, setCheckedCategories] = useState(components.checkedCategories);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    const [defaultMinimumPrice, setDefaultMinimumPrice] = useState(null);
    const [defaultMaximumPrice, setDefaultMaximumPrice] = useState(null);

    const [minimumPrice, setMinimumPrice] = useState(null);
    const [maximumPrice, setMaximumPrice] = useState(null);

    useEffect(() => {
        setAllProducts(components.allProducts);
        setWishlisted(components.wishlist);
        setCategories(components.categories);
        setCheckedCategories(components.checkedCategories);
        setPageCount(Math.ceil(components.allProducts.length / perPage));

        if(defaultMinimumPrice == null || defaultMinimumPrice == 999999999999999)
        {
            if(allProducts.length > 0)
            {
                let setDefaultMinimumPrice_tmp = 999999999999999;
                let setDefaultMaximumPrice_tmp = 0;
                components.allProducts.map((product) => {
                    if(setDefaultMinimumPrice_tmp > parseFloat(product.price))
                    {
                        setDefaultMinimumPrice_tmp = parseFloat(product.price);
                    }

                    if(setDefaultMaximumPrice_tmp < parseFloat(product.price))
                    {
                        setDefaultMaximumPrice_tmp = (parseFloat(product.price)).toFixed(0);
                    }
                })

                setDefaultMinimumPrice(setDefaultMinimumPrice_tmp);
                setDefaultMaximumPrice(setDefaultMaximumPrice_tmp);
                setMinimumPrice(setDefaultMinimumPrice_tmp);
                setMaximumPrice(setDefaultMaximumPrice_tmp);
            }
        }
    }, [components]);

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        // console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        // console.log(result)
    }

    const handleOnSelect = (item) => {
        // the item selected
        // console.log(item)
        // navigate(`/goto/${item.id}`);
        components.setPage('product');
        components.setProductId(item.id);
        // navigate(`/products/${item.id}`);
    }

    const handleOnFocus = () => {
        // console.log('Focused')
    }

    const formatResult = (item) => {
    return (
        <>
            <Link onClick={() => { 
                components.setPage('product');
                components.setProductId(item.id);
            }} style={{ display: 'block', textAlign: 'left' }}>
                <img
                    style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '2px'
                    }} src={
                        item.path != null ? item.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`
                    } 
                />
                {item.name} <small>Model: {item.code}</small>
            </Link>
        </>
    )
    }

    return (
        <>
            <main>
                <div className="container margin_30">
                    <div className="row">
                    <aside className="col-lg-3" id="sidebar_fixed">
                        <div className="card mb-3 shadow-sm">
                            <div className="card-body">
                            <div className="inner_bt">
                                <a href="#" className="open_filters">
                                    {/* <i className="ti-close" /> */}
                                </a>
                            </div>
                            <div className="filter_type version_2">
                                <h4>
                                    <a data-bs-toggle="collapse" className="opened">
                                        หมวดหมู่
                                    </a>
                                </h4>
                                <div className="collapse show" id="filter_1">
                                <ul>
                                    {
                                        categories.length > 0 ? categories.map((category, index) => {
                                            if(category.count <= 0)
                                            {
                                                return;
                                            }
                                            return (
                                                <li key={index}>
                                                    <label className="container_check">
                                                        {category.name} <small>{parseFloat(category.count).toLocaleString("en-US")}</small>
                                                        <input type="checkbox" onClick={(e) => {
                                                            components.updateCheckedCategories(category.id)
                                                        }}/>
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            )
                                        }) : <></>
                                    }
                                    
                                </ul>
                                </div>
                            </div>
                            <div className="filter_type version_2">
                                <h4>
                                    <a data-bs-toggle="collapse" className="opened">
                                        ราคา {parseFloat(minimumPrice).toLocaleString("en-US")} - {parseFloat(maximumPrice).toLocaleString("en-US")}฿
                                    </a>
                                </h4>
                                
                                <div className="collapse show pb-4" id="filter_4">
                                    {
                                        defaultMinimumPrice == null || defaultMaximumPrice == null ? "Loading" : <RangeSlider
                                            defaultValue={[defaultMinimumPrice, defaultMaximumPrice]}
                                            min={defaultMinimumPrice}
                                            max={defaultMaximumPrice}
                                            onInput={(data) => {
                                                setMinimumPrice(data[0])
                                                setMaximumPrice(data[1])
                                            }}
                                        />
                                    }
                                    {/* <ul>
                                        <li>
                                        <label className="container_check">
                                            $0 — $50<small>11</small>
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        </li>
                                        <li>
                                        <label className="container_check">
                                            $50 — $100<small>08</small>
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        </li>
                                        <li>
                                        <label className="container_check">
                                            $100 — $150<small>05</small>
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        </li>
                                        <li>
                                        <label className="container_check">
                                            $150 — $200<small>18</small>
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                            </div>
                        </div>
                    </aside>
                    {/* /col */}
                    <div className="col-lg-9">
                    <ReactSearchAutocomplete
                        items={allProducts}
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        fuseOptions={{ keys: ["name", "code"] }}
                        // resultStringKeyName="code"
                        // autoFocus
                        styling={{"width":"100%","height":"40px","backgroundColor":"#fff","padding":"15px","zIndex":1}}
                        formatResult={formatResult}
                        placeholder="ค้นหาสินค้า..."
                        maxResults={10}
                        showNoResultsText="ไม่พบสินค้าที่คุณค้นหา"
                        className='mb-3'
                    />
                        <div className="row small-gutters">
                                {
                                    allProducts.length > 0 ? allProducts.slice(itemOffset, (itemOffset + perPage)).filter(
                                        (product) => parseInt(parseFloat(maximumPrice).toFixed(0)) >= parseInt(parseFloat(product.price).toFixed(0)) && parseInt(parseFloat(minimumPrice).toFixed(0)) <= parseInt(parseFloat(product.price).toFixed(0))
                                    ).map((product, index) => {
                                        const hasWishlist = wishlisted.some((wishlist) => { 
                                            return wishlist.product_id == product.id
                                        });
                                        
                                        return (
                                            <div className="col-6 col-md-4" key={index}>
                                                <div className="grid_item">
                                                    <figure>
                                                        <Link onClick={() => {
                                                            components.setPage('product')
                                                            components.setProductId(product.id)
                                                        }}>
                                                        <img
                                                            className="img-fluid lazy"
                                                            src={product.path != null ? product.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                            data-src={product.path != null ? product.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                            alt=""
                                                        />
                                                        </Link>
                                                        {/* <div data-countdown="2024/05/15" className="countdown" /> */}
                                                    </figure>
                                                    <Link onClick={() => {
                                                        components.setPage('product')
                                                        components.setProductId(product.id)
                                                    }}>
                                                        <h3>
                                                            <b>
                                                                {product.name}
                                                            </b>
                                                        </h3>
                                                    </Link>
                                                    <div className="price_box">
                                                        <span className="new_price">
                                                            {
                                                                product.price != null ? parseFloat(product.price).toLocaleString("en-US") : parseFloat(0.00).toLocaleString("en-US")
                                                            }฿
                                                        </span>
                                                        {/* <span className="old_price">$60.00</span> */}
                                                    </div>
                                                    <ul>
                                                        {/* <li>
                                                            <a
                                                                className="tooltip-1"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="left"
                                                                title="Add to compare"
                                                            >
                                                                <i className="ti-control-shuffle" />
                                                                <span>Add to compare</span>
                                                            </a>
                                                        </li> */}
                                                        {/* <li>
                                                            <a
                                                                className="tooltip-1"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="left"
                                                                title="Add to cart"
                                                            >
                                                                <i className="ti-shopping-cart" />
                                                                <span>Add to cart</span>
                                                            </a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    }) : <div className="col-6 col-md-4">ไม่พบสินค้าที่ค้นหา</div>
                                }
                        </div>
                        {/* /row */}
                        <div className="pagination__wrapper" key={`productpagination`}>
                            <ul className="pagination">
                                <li>
                                    <a className="prev" title="Previous page" onClick={() => {
                                        if(page > 1)
                                        {
                                            setItemOffset(((page + 1) * perPage) % allProducts.length);
                                            setPage(page - 1);
                                        }
                                    }}>
                                        ❮
                                    </a>
                                </li>
                                    {
                                        allProducts.length > 0 ? [...Array(pageCount)].map((x,i) => {
                                            return (
                                                <li key={i}>
                                                    <a className={
                                                        page == (i + 1) ? "active" : ""
                                                    } onClick={() => {
                                                        setPage(i + 1);
                                                        setItemOffset(((i) * perPage) % allProducts.length);
                                                    }}>
                                                        {i + 1}
                                                    </a>
                                                </li>
                                            )
                                        }) : <li key={0}>
                                                <a href="#0" className="active">
                                                    1
                                                </a>
                                            </li>
                                    }
                                <li>
                                    <a className="next" title="Next page" onClick={() => {
                                        if(page < pageCount)
                                        {
                                            setItemOffset(((page) * perPage) % allProducts.length);
                                            setPage(page + 1);
                                        }
                                    }}>
                                        ❯
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* /col */}
                    </div>
                    {/* /row */}
                </div>
                {/* /container */}
                </main>
        </>
    )
}

export default AllProducts;

// export default (components) => {
//     console.log(components)
//     return (
//         <>
//         </>
//     )
// }