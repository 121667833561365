import { useEffect, useState, useRef } from "react";
import Cookies from "universal-cookie";
import axios from "axios";

const WebInfomation = (components) => {
    const cookies = new Cookies(null, { path: '/' });
    const [webInformation, setWebInformation] = useState({});
    const nameRef = useRef(null);
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const emailRef = useRef(null);
    const phone_numberRef = useRef(null);
    const facebookRef = useRef(null);
    const youtubeRef = useRef(null);
    const lineRef = useRef(null);
    const aboutRef = useRef(null);
    const addressRef = useRef(null);
    const mapRef = useRef(null);

    useEffect(() => {
        setWebInformation(components.webInformation)
    }, [components])

    const updateWebinformation = (event) => {
        event.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/information/update`, {
            name: nameRef.current.value,
            title: titleRef.current.value,
            description: descriptionRef.current.value,
            email: emailRef.current.value,
            phone_number: phone_numberRef.current.value,
            facebook: facebookRef.current.value,
            youtube: youtubeRef.current.value,
            line: lineRef.current.value,
            about: aboutRef.current.value,
            address: addressRef.current.value,
            map_url: mapRef.current.value
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success('อัพเดทข้อมูลเว็บไซต์เรียบร้อยแล้ว');
                components.reloadWebInformation();
            }
            else
            {
                components.notify_error(`อัพเดทข้อมูลเว็บไซต์ไม่สำเร็จ (${response.data.message})`);
            }
        })
    }

    return (
        <div className="my-3">
            <form onSubmit={updateWebinformation}> 
                <div className="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="name">
                                ชื่อเว็บไซต์
                            </label>
                            <input
                                ref={nameRef}
                                type="text"
                                className="form-control"
                                name="name"
                                id="name"
                                placeholder="ชื่อเว็บไซต์"
                                defaultValue={webInformation.name}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="title">
                                หัวเว็บของเว็บไซต์ (Title)
                            </label>
                            <input
                                ref={titleRef}
                                type="text"
                                className="form-control"
                                name="title"
                                id="title"
                                placeholder="หัวเว็บของเว็บไซต์ (Title)"
                                defaultValue={webInformation.title}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="description">
                                คำอธิบายของเว็บไซต์
                            </label>
                            <input
                                ref={descriptionRef}
                                type="text"
                                className="form-control"
                                name="description"
                                id="description"
                                placeholder="คำอธิบายของเว็บไซต์"
                                defaultValue={webInformation.description}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="email">
                                อีเมลล์
                            </label>
                            <input
                                ref={emailRef}
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="อีเมลล์"
                                defaultValue={webInformation.email}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="phone_number">
                                เบอร์โทรศัพท์
                            </label>
                            <input
                                ref={phone_numberRef}
                                type="text"
                                className="form-control"
                                name="phone_number"
                                id="phone_number"
                                placeholder="เบอร์โทรศัพท์ (ใช้ , ในการคั่น ในกรณีมีหลายเบอร์)"
                                defaultValue={webInformation.phone_number}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="facebook">
                                Facebook Link
                            </label>
                            <input
                                ref={facebookRef}
                                type="text"
                                className="form-control"
                                name="facebook"
                                id="facebook"
                                placeholder="Facebook Link"
                                defaultValue={webInformation.facebook}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="youtube">
                                Youtube Link
                            </label>
                            <input
                                ref={youtubeRef}
                                type="text"
                                className="form-control"
                                name="youtube"
                                id="youtube"
                                placeholder="Youtube Link"
                                defaultValue={webInformation.youtube}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="line">
                                Line ID
                            </label>
                            <input
                                ref={lineRef}
                                type="text"
                                className="form-control"
                                name="line"
                                id="line"
                                placeholder="Line ID"
                                defaultValue={webInformation.line}
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <div className="form-group">
                            <label for="about">
                                เกี่ยวกับเรา
                            </label>
                            <input
                                ref={aboutRef}
                                type="text"
                                className="form-control"
                                name="about"
                                id="about"
                                placeholder="เกี่ยวกับเรา"
                                defaultValue={webInformation.about}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="address">
                                ที่อยู่
                            </label>
                            <input
                                ref={addressRef}
                                type="text"
                                className="form-control"
                                name="address"
                                id="address"
                                placeholder="ที่อยู่"
                                defaultValue={webInformation.address}
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label for="map">
                                Map Link
                            </label>
                            <input
                                ref={mapRef}
                                type="text"
                                className="form-control"
                                name="map"
                                id="map"
                                placeholder="Map Link"
                                defaultValue={webInformation.map_url}
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <button type="submit" className="btn_1 full-width">
                            อัพเดท
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default WebInfomation;