'use client'
import axios from 'axios';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import toast, { Toaster } from 'react-hot-toast';
const cookies = new Cookies(null, { path: '/' });

function LoginAdmin(components) {
    const notify = (msg, options) => toast(msg, {...options});
    const navigate = useNavigate();

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const LoginSubmit = async (event) => {
        event.preventDefault();

        const sendData = {
            email: emailRef.current.value,
            password: passwordRef.current.value
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/members/admin/login/`, sendData);
        if(response.data.message === 'Logged in')
        {
            cookies.set('token', response.data.token);
            components.setStatusToken(true);
            components.reloadCarts();
            components.reloadWishlist();
            components.reloadStatusAdmin();
            return components.notify_success("เข้าสู่ระบบผู้ดูแลเรียบร้อยแล้ว");
        }

        components.notify_error(response.data.message);
    }

    return (
        <form onSubmit={LoginSubmit}>
            <div className="form-group">
                <input
                    ref={emailRef}
                    type="text"
                    className="form-control"
                    name="login_email"
                    id="login_email"
                    placeholder="Email*"
                />
            </div>
            <div className="form-group">
                <input
                    ref={passwordRef}
                    type="password"
                    className="form-control"
                    name="login_password"
                    id="login_password"
                    defaultValue=""
                    placeholder="Password*"
                />
            </div>
            <div className="clearfix add_bottom_15">
                <div className="float-end">
                <a id="forgot" href="javascript:void(0);">
                    ลืมรหัสผ่าน ?
                </a>
                </div>
            </div>
            <div className="text-center">
                <button
                    type="submit"
                    className="btn_1 full-width"
                >
                    เข้าสู่ระบบ
                </button>
            </div>
        </form>
    );
}

export default LoginAdmin;