import '../../assets/css/cart.css'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const WishList = (components) => {
    const [wishlistsData, setWishlistsData] = useState([])
    const wishlists = components.wishlists;
    const allProducts = components.allProducts;

    useEffect(() => {
        const wishlistProducts = wishlists.map((wishlistItem) => {
            const matchingProduct = allProducts.find((product) => product.id === wishlistItem.product_id) || {};
            return matchingProduct;
        });
        setWishlistsData(wishlistProducts);
    }, [allProducts]);

    return (
        <main className="bg_gray">
            <div className="container margin_30">
                <div className="page_header">
                    <h1>สินค้าที่ถูกใจ</h1>
                </div>
                <table className="table table-striped wishlist-list">
                    <thead>
                        <tr>
                            <th>สินค้า</th>
                            <th>ราคา</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            wishlistsData.length > 0 ? wishlistsData.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="thumb_cart">
                                                <img
                                                    src={data.path != null ? data.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                    data-src={data.path != null ? data.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                    className="lazy"
                                                    alt="Image"
                                                />
                                            </div>
                                            <span className="item_cart">
                                                <Link to={`/goto/${data.id}`}>
                                                    {data.name}
                                                </Link>
                                            </span>
                                        </td>
                                        <td>
                                            <strong>{data.price}฿</strong>
                                        </td>
                                        <td>
                                            <Link type="button" className='btn_1' to={`/goto/${data.id}`}>
                                                ดูสินค้า
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan={5} className='text-center'>ไม่มีสินค้าที่ถูกใจ</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {/* /container */}
        </main>
    )
}

export default WishList;