import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Goto = () => {
    const params = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        navigate(`/products/${params.product_id}`)
    }, [])

    return <>
        
    </>
}

export default Goto;