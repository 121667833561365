import parse from 'html-react-parser';

const MoreDetails = (data) => {
    return (
        <>
            <div className="tabs_product">
                <div className="container">
                    <h2>รายละเอียดเพิ่มเติม</h2>
                    {parse(data.description)}
                </div>
            </div>
            {/* /tabs_product */}
            
        </>
    )
}

export default MoreDetails;