import Register from './Register';
import Login from './Login';
import Cookies from 'universal-cookie';
import Account from './Account';
import '../../assets/css/account.css'

const LoginOrRegister = (components) => {
    const cookies = new Cookies(null, { path: '/' });

    return !cookies.get('token') ? (
        <main className="bg_gray">
            <div className="container margin_30">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-8">
                <div className="box_account">
                    <h3 className="client">มีบัญชีผู้ใช้อยู่แล้ว</h3>
                    <div className="form_container">
                        <Login {...components} />
                    </div>
                </div>

                </div>
                <div className="col-xl-6 col-lg-6 col-md-8">
                <div className="box_account">
                    <h3 className="new_client">สร้างบัญชีผู้ใช้ใหม่</h3>{" "}
                    <small className="float-right pt-2">* จำเป็น</small>
                    <div className="form_container">
                        <Register {...components} />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </main>
    ) : (
        <Account />
    )
}

export default LoginOrRegister;