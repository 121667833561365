import Js from "../../Js"

const ProductDetail = (components) => {
    const productImg = components.productImg;
    return (
        <div className="all">
            <div className="slider">
                <div className="owl-carousel owl-theme main">
                    {
                        productImg.map((img, idx) => {
                            return (
                                <div
                                    style={{ backgroundImage: `url(${img.path.replace(`{api_path}`, process.env.REACT_APP_API_URL)})`  }}
                                    className="item-box"
                                    key={idx}
                                />
                            )
                        })
                    }
                </div>
                <div className="left nonl">
                    <i className="ti-angle-left" />
                </div>
                <div className="right">
                    <i className="ti-angle-right" />
                </div>
            </div>
            <div className="slider-two">
                <div className="owl-carousel owl-theme thumbs">
                    {
                        productImg.map((img, idx) => {
                            return (
                                <div
                                    style={{ backgroundImage: `url(${img.path.replace(`{api_path}`, process.env.REACT_APP_API_URL)})`  }}
                                    className={idx == 0 ? "item active" : "item"}
                                    key={idx}
                                />
                            )
                        })
                    }
                </div>
            <div className="left-t nonl-t" />
            <div className="right-t" />
            </div>
            <Js scriptURL={`${process.env.REACT_APP_BASE_URL}/js/carousel_with_thumbs.js`} />
        </div>
    )
}

export default ProductDetail;