import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import './assets/css/Social_Menu.css'
import menu from './assets/img/chat.png'
import phone from './assets/img/telephone-call.png'
import line from './assets/img/line.png'
import messenger from './assets/img/messenger.png'
import google_maps from './assets/img/google-maps.png'
import close from './assets/img/delete-button.png'

const Social_Menu = (components) => {
    const [menuOpen, setOpenMenu] = useState(true);

    const MenuOpen = () => {
        setOpenMenu(!menuOpen)
    }

    const [webInformation, setWebInformation] = useState({});

    useEffect(() => {
        setWebInformation(components.webInformation)
    }, [components]);

    return (
        <div className='Social_Menu'>
            <div className={menuOpen ? 'hiden' : 'Up'}>
               
                {
                    <div style={
                        {
                            marginBottom: '0.5rem'
                        }
                    }>
                        <p>ดูแผนที่ </p>
                        <Link to={webInformation.map_url} target="_blank">
                            <img src={google_maps} />
                        </Link>
                    </div>
                }
                {
                    webInformation.facebook != null && <div style={
                        {
                            marginBottom: '0.5rem'
                        }
                    }>
                        <p>แชทผ่าน facebook </p>
                        <Link to={webInformation.facebook} target="_blank">
                            <img src={messenger} />
                        </Link>
                    </div>
                }
                {
                    webInformation.line != null && <div style={
                        {
                            marginBottom: '0.5rem'
                        }
                    }>
                        <p>แชทผ่านไลน์ </p>
                        <Link to={`https://line.me/ti/p/~${webInformation.line}`} target="_blank">
                            <img src={line} />
                        </Link>
                    </div>
                }
                
                {
                    webInformation.phone_number != undefined && webInformation.phone_number.split(',').map((number, idx) => {
                        return (
                            <div style={
                                {
                                    marginBottom: '0.5rem'
                                }
                            }>
                                <p>โทร {number}</p>
                                <Link to={`tel:${number}`}>
                                    <img src={phone} />
                                </Link>
                            </div>
                        )
                    })
                }
                

            </div>
            <br />
            <div className="Button_Social">
                <div >
                    <p></p>
                    <img src={menuOpen ? menu : close} alt="" onClick={MenuOpen} />
                </div>
            </div>
        </div>
    )
}

export default Social_Menu;