import axios from "axios";
import { useState, useEffect, useRef } from "react";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import Select from 'react-select'

const NewAddress = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: '/' });
    const notify = (msg, options) => toast(msg, {...options});

    const notify_error = (msg) => {
        notify(msg, {
            duration: 5000,
            style: {
                color: "white",
                backgroundColor: "red"
            },
            icon: '❌',
            position: 'bottom-center',
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
        });
    }

    const notify_success = (msg) => {
        notify(msg, {
            duration: 3000,
            style: {
                color: "white",
                backgroundColor: "#004dda"
            },
            icon: '✔️',
            position: 'bottom-center',
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
        });
    }

    const firstnameRef = useRef(null);
    const lastnameRef = useRef(null);
    const addressRef = useRef(null);
    const provinceRef = useRef(null);
    const zip_codeRef = useRef(null);
    const phoneNumberRef = useRef(null);

    const [enableAddAddress, setEnableAddAddress] = useState(true);

    const addAddressSubmit = async (event) => {
        event.preventDefault();
        if(!enableAddAddress)
        {
            return;
        }

        if(firstnameRef === undefined || lastnameRef === undefined || addressRef === undefined || provinceRef === undefined || zip_codeRef === undefined || phoneNumberRef === undefined)
        {
            return notify_error('ไม่พบข้อมูลที่คุณกรอก');
        }

        if(firstnameRef.current.value == '' || lastnameRef.current.value == '' || addressRef.current.value == '' || provinceRef.current.value == '' || zip_codeRef.current.value == '' || phoneNumberRef.current.value == '')
        {
            return notify_error('กรุณากรอกข้อมูลให้ครบทุกช่อง');
        }

        setEnableAddAddress(false)
        axios.post(`${process.env.REACT_APP_API_URL}/members/addAddress`, {
            firstname: firstnameRef.current.value,
            lastname: lastnameRef.current.value,
            address: addressRef.current.value,
            province: provinceRef.current.value,
            zip_code: zip_codeRef.current.value,
            phone: phoneNumberRef.current.value
        }, {
            headers: {
                token: cookies.get('token') || null
            }
        }).then((response) => {
            setEnableAddAddress(true)
            if(response.data.message == 'success')
            {
                notify_success('เพิ่มที่อยู่ของคุณเรียบร้อยแล้ว');
                return setTimeout(() => {
                    navigate('/account');
                }, 3000)
            }
            else
            {
                return notify_error(`เกิดข้อผิดพลาดในการเพิ่มที่อยู่ กรุณาลองใหม่อีกครั้งภายหลัง (${response.data.message})`)
            }
        })
    }

    return (
        <main className="bg_gray">
            <Toaster />
            <div className="container margin_30">
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="box_account">
                            <div className="form_container" style={{
                                borderRadius: "15px"
                            }}>
                                <h3 className="client">เพิ่มที่อยู่</h3>
                                <hr/>

                                <form onSubmit={addAddressSubmit}>
                                    <div className="row no-gutters">
                                        <div className="col-6 form-group pr-1">
                                            <input type="text" className="form-control" placeholder="ชื่อจริง" ref={firstnameRef} />
                                        </div>
                                        <div className="col-6 form-group pl-1">
                                            <input type="text" className="form-control" placeholder="นามสกุล" ref={lastnameRef} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="ที่อยู่" ref={addressRef} />
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-6 form-group pr-1">
                                            <input type="text" className="form-control" placeholder="จังหวัด" ref={provinceRef} />
                                        </div>
                                        <div className="col-6 form-group pl-1">
                                            <input type="text" className="form-control" placeholder="รหัสไปรษณีย์" ref={zip_codeRef} maxLength={5} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="เบอร์โทร" ref={phoneNumberRef} maxLength={10} />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn_1 full-width" >
                                            {
                                                enableAddAddress ? <>
                                                    <i className="ti-check"></i> เพิ่มที่อยู่
                                                </> : <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span className="sr-only"> กำลังทำรายการ...</span>
                                                </>
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NewAddress;