import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const GotoBlog = () => {
    const params = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        navigate(`/blog/${params.blog_id}`)
    }, [])

    return <>
    
    </>
}

export default GotoBlog;