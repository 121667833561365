import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'

const AllCategories = (components) => {
    const cookies = new Cookies(null, { path: '/' });

    const [allCategories, setAllCategories] = useState([]);
    const createCategoryNameRef = useRef(null);

    useEffect(() => {
        setAllCategories(components.categories)
    }, [components.categories])

    const addCategory = (event) => {
        event.preventDefault();

        if(createCategoryNameRef.current.value == '' || createCategoryNameRef.current.value == undefined)
        {
            return components.notify_error('กรุณากรอกชื่อหมวดหมู่ก่อนเพิ่ม');
        }

        axios.post(`${process.env.REACT_APP_API_URL}/categories/add`, {
            name: createCategoryNameRef.current.value
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success('เพิ่มหมวดหมู่เรียบร้อยแล้ว');
                components.reloadCategories();
                components.reloadProducts();
            }
            else
            {
                components.notify_error(`เพิ่มหมวดหมู่ไม่สำเร็จ (${response.data.message})`);
            }
        })
    }

    const removeCategory = (category_id) => {
        Swal.fire({
            title: "ยืนยันหรือไม่ ?",
            text: "แน่ใจที่จะลบหมวดหมู่สินค้าใช่หรือไม่ หากใช่สินค้าในหมวดหมู่นี้จะถูกลบออกไปทั้งหมด",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "ใช่, ลบเลย!",
            cancelButtonText: "ไม่, ยกเลิก!",
            dangerMode: true,
        }).then(function(result) {
            if(result.isConfirmed)
            {
                axios.post(`${process.env.REACT_APP_API_URL}/categories/delete`, {
                    id: category_id
                }, {
                    headers: {
                        token: cookies.get('token')
                    }
                }).then((response) => {
                    if(response.data.message == 'OK')
                    {
                        components.notify_success('ลบหมวดหมู่เรียบร้อยแล้ว');
                        components.reloadCategories();
                        components.reloadProducts();
                    }
                    else
                    {
                        components.notify_error(`ลบหมวดหมู่ไม่สำเร็จ (${response.data.message})`);
                    }
                })
            }
        })
        
    }

    return (
        <>
            <div className="col-12 mt-3">
                <form onSubmit={addCategory}>
                    <div className="row">
                        <div className="col-6">
                            <label for="category_name">
                                ชื่อหมวดหมู่:
                            </label>
                            <input type="text" name="category_name" id="category_name" placeholder="ชื่อหมวดหมู่" className="form-control" ref={createCategoryNameRef}/>
                        </div>
                        <div className="col-6">
                            <button type="submit" className="btn_1" style={{ height: '100%', width: '100%' }}>
                                เพิ่มหมวดหมู่
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <hr/>
            <table className="table table-striped wishlist-list">
                <thead>
                    <tr>
                        <th>ชื่อหมวดหมู่</th>
                        <th>จำนวนสินค้าในหมวดหมู่</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        allCategories.length > 0 ? allCategories.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <strong>{data.name}</strong>
                                    </td>
                                    <td>
                                        {data.count} รายการ
                                    </td>
                                    <td>
                                        <Link onClick={() => {
                                            removeCategory(data.id)
                                        }}>
                                            <i className="ti-trash" />
                                        </Link>
                                    </td>
                                </tr>
                            )
                        }) : <tr>
                            <td colSpan={5} className='text-center'>ไม่มีสินค้าที่ถูกใจ</td>
                        </tr>
                    }
                </tbody>
            </table>
        </>
    )
}

export default AllCategories;