import { useEffect } from "react";

const Js = (data) => {
    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = data.scriptURL;
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
    }, [data]);
}

export default Js;