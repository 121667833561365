'use client'
import axios from 'axios';
import { useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import toast, { Toaster } from 'react-hot-toast';
const cookies = new Cookies(null, { path: '/' });

function Register(components) {
    const notify = (msg, options) => toast(msg, {...options});
    const [confirmTermsOfUse, setConfirmTermsOfUse] = useState(false);
    const navigate = useNavigate();
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const passwordConfirmRef = useRef(null);
    const phoneNumberRef = useRef(null);

    const toggleCheckedTerms = () => {
        setConfirmTermsOfUse(!confirmTermsOfUse);
    }

    const RegisterSubmit = async (event) => {
        event.preventDefault();
        const matchPassword = checkMatchPassword(passwordRef.current.value, passwordConfirmRef.current.value);
        
        if(!matchPassword)
        {
            return;
        }

        if(!confirmTermsOfUse)
        {
            notify('คุณยังไม่ได้ยอมรับ ข้อกำหนดการใช้งาน ของเรา', {
                duration: 5000,
                style: {
                    color: "white",
                    backgroundColor: "red"
                },
                icon: '❌',
                position: 'bottom-center',
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
            });
            return;
        }

        const sendData = {
            email: emailRef.current.value,
            password: passwordRef.current.value,
            passwordConfirm: passwordConfirmRef.current.value,
            phoneNumber: phoneNumberRef.current.value
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/members/register/`, sendData);
        if(response.data.message === 'Already registered')
        {
            cookies.set('token', response.data.token);
            components.setStatusToken(true);
            components.reloadCarts();
            components.reloadWishlist();
            return navigate('/');
        }
        
        notify(response.data.message, {
            duration: 5000,
            style: {
                color: "white",
                backgroundColor: "red"
            },
            icon: '❌',
            position: 'bottom-center',
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
        });
    }

    const checkMatchPassword = (password, confirmPassword) => {
        return password === confirmPassword ? (password == '' ? false : true) : false;
    }

    const onKeydownPassword = () => {
        const matchPassword = checkMatchPassword(passwordRef.current.value, passwordConfirmRef.current.value);
    }

    return (
        <form onSubmit={RegisterSubmit}>
            <Toaster />
            <div className="form-group">
                <input
                ref={emailRef}
                type="email"
                className="form-control"
                name="register_email"
                id="register_email"
                placeholder="Email*"
                />
            </div>
            <div className="form-group">
                <input
                ref={passwordRef}
                type="password"
                className="form-control"
                name="register_password"
                id="register_password"
                defaultValue=""
                placeholder="Password*"
                onKeyUp={onKeydownPassword}
                />
            </div>
            <div className="form-group">
                <input
                ref={passwordConfirmRef}
                type="password"
                className="form-control"
                name="register_password_confirm"
                id="register_password_confirm"
                defaultValue=""
                placeholder="Confirm Password*"
                onKeyUp={onKeydownPassword}
                />
            </div>
            <div className="form-group">
                <input
                ref={phoneNumberRef}
                type="text"
                className="form-control"
                name="register_phone_number"
                id="register_phone_number"
                defaultValue=""
                placeholder="Phone number*"
                />
            </div>
            <hr />
            <div className="form-group">
                <label className="container_check">
                    การสมัครบัญชีผู้ใช้ใหม่ เราถือว่ายอมรับ <Link to={'/'}>ข้อกำหนดการใช้งาน</Link> ของเราแล้ว
                    <input type="checkbox" onClick={toggleCheckedTerms} />
                    <span className="checkmark" />
                </label>
            </div>
            <div className="text-center">
                <button type="submit" className="btn_1 full-width">
                    ยืนยัน สร้างบัญชีผู้ใช้ใหม่
                </button>
            </div>
        </form>
    );
}

export default Register;