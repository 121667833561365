import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";

const Banner = (components) => {
    const cookies = new Cookies(null, { path: '/' });
    const fileRef = useRef(null);
    const [banner, setBanner] = useState([]);

    useEffect(() => {
        setBanner(components.banner);
    }, [components]);

    const removeBanner = (filename) => {
        axios.post(`${process.env.REACT_APP_API_URL}/banner/delete`, {
            filename: filename
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success('ลบรูปภาพเรียบร้อยแล้ว');
                components.reloadBanner();
            }
            else
            {
                components.notify_error(`ลบรูปภาพไม่สำเร็จ ${response.data.message}`);
            }
        })
    }

    const uploadBanner = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        
        axios.post(`${process.env.REACT_APP_API_URL}/banner/upload`, formData, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success('อัพโหลดรูปภาพเรียบร้อยแล้ว');
                components.reloadBanner();
            }
            else
            {
                components.notify_error(`อัพโหลดรูปภาพไม่สำเร็จ (${response.data.message})`)
            }
        })
    }

    return (
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 my-3">
                <input name="file_img" id="file_img" ref={fileRef} type="file" className="form-control"/>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 my-3">
                <button type="button" className="btn_1 full-width" onClick={() => {
                    uploadBanner(fileRef.current.files[0]);
                }}>
                    เพิ่มรูปภาพ
                </button>
            </div>
            <hr/>
            {
                banner.length > 0 && banner.map((bn, index) => {
                    return (
                        <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                            <div className="grid_item">
                                <figure>
                                    <img className="img-fluid" src={`${process.env.REACT_APP_API_URL}/banner/get/${bn}`}/>
                                </figure>
                                <ul>
                                    <li>
                                        <Link
                                            className="tooltip-1 bg-danger text-white"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="left"
                                            title="ลบรูปภาพ"
                                            onClick={() => {
                                                removeBanner(bn);
                                            }}
                                        >
                                            <i className="ti-trash" />
                                            <span>ลบรูปภาพ</span>
                                        </Link>
                                        
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Banner;