import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import './../../assets/css/blog.css';
import axios from 'axios';
import { marked } from "marked";
import parse from 'html-react-parser';

const Blog = (components) => {
    const navigate = useNavigate();
    const params = useParams();

    const [allBlogs, setAllBlogs] = useState(components.allBlogs);
    const [blogIdx, setBlogIdx] = useState(-1);
    const [read, setRead] = useState(false);

    useEffect(() => {
        setAllBlogs(components.allBlogs);
        if(components.allBlogs.length > 0)
        {
            const isBlogIdMatch = (element) => element.id == params.blog_id;
            const idx = components.allBlogs.findIndex(isBlogIdMatch);
            setBlogIdx(idx);
        }
    }, [components]);

    useEffect(() => {
        if(!read && blogIdx != -1)
        {
            axios.post(`${process.env.REACT_APP_API_URL}/blogs/read`, {
                blog_id: allBlogs[blogIdx].id
            }).then((response) => {
                // console.log(response.data)
            })
            setRead(true);
        }
    }, [read, blogIdx, params])

    const removeTagHTML = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/(<([^>]+)>)/ig, '');
    }

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        // console.log(string, results)
      }
    
      const handleOnHover = (result) => {
        // the item hovered
        // console.log(result)
      }
    
      const handleOnSelect = (item) => {
        // the item selected
        // console.log(item)
        navigate(`/gotoblog/${item.id}`);
        // navigate(`/products/${item.id}`);
      }
    
      const handleOnFocus = () => {
        // console.log('Focused')
      }
    
      const formatResult = (item) => {
        return (
          <>
            <a href={`/gotoblog/${item.id}`} style={{ display: 'block', textAlign: 'left' }}>{item.title} <small>เขียนเมื่อ: {item.createdAt}</small></a>
          </>
        )
      }

    return (
        <main className="bg_gray">
            <div className="container margin_30">
                {
                    allBlogs.length > 0 && blogIdx != -1 ? <>
                        <div className="page_header">
                            <div className="breadcrumbs">
                                <ul>
                                    <li>
                                        <Link to="/">หน้าหลัก</Link>
                                    </li>
                                    <li>
                                        <Link to="/blogs">บทความทั้งหมด</Link>
                                    </li>
                                    <li>
                                        {allBlogs[blogIdx].title}
                                    </li>
                                </ul>
                            </div>
                            </div>
                            {/* /page_header */}
                            <div className="row">
                            <div className="col-lg-9">
                                <div className="singlepost">
                                    <h1>{allBlogs[blogIdx].title}</h1>
                                    <div className="postmeta">
                                        <ul>
                                            {/* <li>
                                                <a href="#">
                                                <i className="ti-folder" /> Category
                                                </a>
                                            </li> */}
                                            <li>
                                                <i className="ti-calendar" /> {allBlogs[blogIdx].createdAt}
                                            </li>
                                            <li>
                                                <i className="ti-eye" /> อ่านแล้ว {allBlogs[blogIdx].read} ครั้ง
                                            </li>
                                            {/* <li>
                                                <a href="#">
                                                <i className="ti-comment" /> (14) Comments
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                    {/* /post meta */}
                                    <div className="post-content mb-3">
                                        {
                                            parse(marked.parse(allBlogs[blogIdx].html))
                                            // parse(allBlogs[blogIdx].html)
                                        }
                                    </div>
                                    {/* /post */}
                                </div>
                                {/* /single-post */}
                                {/* <div id="comments">
                                <h5>Comments</h5>
                                <ul>
                                    <li>
                                    <div className="avatar">
                                        <a href="#">
                                        <img src="img/avatar1.jpg" alt="" />
                                        </a>
                                    </div>
                                    <div className="comment_right clearfix">
                                        <div className="comment_info">
                                        By <a href="#">Anna Smith</a>
                                        <span>|</span>25/10/2019<span>|</span>
                                        <a href="#">
                                            <i className="icon-reply" />
                                        </a>
                                        </div>
                                        <p>
                                        Nam cursus tellus quis magna porta adipiscing. Donec et eros
                                        leo, non pellentesque arcu. Curabitur vitae mi enim, at
                                        vestibulum magna. Cum sociis natoque penatibus et magnis dis
                                        parturient montes, nascetur ridiculus mus. Sed sit amet sem a
                                        urna rutrumeger fringilla. Nam vel enim ipsum, et congue ante.
                                        </p>
                                    </div>
                                    <ul className="replied-to">
                                        <li>
                                        <div className="avatar">
                                            <a href="#">
                                            <img src="img/avatar2.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="comment_right clearfix">
                                            <div className="comment_info">
                                            By <a href="#">Anna Smith</a>
                                            <span>|</span>25/10/2019<span>|</span>
                                            <a href="#">
                                                <i className="icon-reply" />
                                            </a>
                                            </div>
                                            <p>
                                            Nam cursus tellus quis magna porta adipiscing. Donec et
                                            eros leo, non pellentesque arcu. Curabitur vitae mi enim,
                                            at vestibulum magna. Cum sociis natoque penatibus et
                                            magnis dis parturient montes, nascetur ridiculus mus. Sed
                                            sit amet sem a urna rutrumeger fringilla. Nam vel enim
                                            ipsum, et congue ante.
                                            </p>
                                            <p>
                                            Aenean iaculis sodales dui, non hendrerit lorem rhoncus
                                            ut. Pellentesque ullamcorper venenatis elit idaipiscingi
                                            Duis tellus neque, tincidunt eget pulvinar sit amet,
                                            rutrum nec urna. Suspendisse pretium laoreet elit vel
                                            ultricies. Maecenas ullamcorper ultricies rhoncus. Aliquam
                                            erat volutpat.
                                            </p>
                                        </div>
                                        <ul className="replied-to">
                                            <li>
                                            <div className="avatar">
                                                <a href="#">
                                                <img src="img/avatar2.jpg" alt="" />
                                                </a>
                                            </div>
                                            <div className="comment_right clearfix">
                                                <div className="comment_info">
                                                By <a href="#">Anna Smith</a>
                                                <span>|</span>25/10/2019<span>|</span>
                                                <a href="#">
                                                    <i className="icon-reply" />
                                                </a>
                                                </div>
                                                <p>
                                                Nam cursus tellus quis magna porta adipiscing. Donec
                                                et eros leo, non pellentesque arcu. Curabitur vitae mi
                                                enim, at vestibulum magna. Cum sociis natoque
                                                penatibus et magnis dis parturient montes, nascetur
                                                ridiculus mus. Sed sit amet sem a urna rutrumeger
                                                fringilla. Nam vel enim ipsum, et congue ante.
                                                </p>
                                                <p>
                                                Aenean iaculis sodales dui, non hendrerit lorem
                                                rhoncus ut. Pellentesque ullamcorper venenatis elit
                                                idaipiscingi Duis tellus neque, tincidunt eget
                                                pulvinar sit amet, rutrum nec urna. Suspendisse
                                                pretium laoreet elit vel ultricies. Maecenas
                                                ullamcorper ultricies rhoncus. Aliquam erat volutpat.
                                                </p>
                                            </div>
                                            </li>
                                        </ul>
                                        </li>
                                    </ul>
                                    </li>
                                    <li>
                                    <div className="avatar">
                                        <a href="#">
                                        <img src="img/avatar3.jpg" alt="" />
                                        </a>
                                    </div>
                                    <div className="comment_right clearfix">
                                        <div className="comment_info">
                                        By <a href="#">Anna Smith</a>
                                        <span>|</span>25/10/2019<span>|</span>
                                        <a href="#">
                                            <i className="icon-reply" />
                                        </a>
                                        </div>
                                        <p>Cursus tellus quis magna porta adipiscin</p>
                                    </div>
                                    </li>
                                </ul>
                                </div> */}
                                {/* <hr />
                                <h5>Leave a comment</h5>
                                <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name2"
                                        className="form-control"
                                        placeholder="Name"
                                    />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                    <input
                                        type="text"
                                        name="email"
                                        id="email2"
                                        className="form-control"
                                        placeholder="Email"
                                    />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                    <input
                                        type="text"
                                        name="email"
                                        id="website3"
                                        className="form-control"
                                        placeholder="Website"
                                    />
                                    </div>
                                </div>
                                </div>
                                <div className="form-group">
                                <textarea
                                    className="form-control"
                                    name="comments"
                                    id="comments2"
                                    rows={6}
                                    placeholder="Comment"
                                    defaultValue={""}
                                />
                                </div>
                                <div className="form-group">
                                <button type="submit" id="submit2" className="btn_1 add_bottom_15">
                                    Submit
                                </button>
                                </div> */}
                            </div>
                            <aside className="col-lg-3">
                                <div className="widget search_blog d-none d-sm-none d-md-none d-lg-block">
                                    <ReactSearchAutocomplete
                                        items={allBlogs}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        fuseOptions={{ keys: ["title"] }}
                                        // resultStringKeyName="code"
                                        // autoFocus
                                        styling={{"width":"100%","height":"40px","backgroundColor":"#fff","padding":"15px","zIndex":1}}
                                        formatResult={formatResult}
                                        placeholder="ค้นหาบทความได้ที่นี่..."
                                        maxResults={3}
                                        showNoResultsText="ไม่พบบทความที่คุณค้นหา"
                                    />
                                </div>
                                {/* /widget */}
                                <div className="widget">
                                <div className="widget-title">
                                    <h4>บทความล่าสุด</h4>
                                </div>
                                <ul className="comments-list">
                                    {
                                        allBlogs.length > 0 ? allBlogs.map((blog, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="alignleft">
                                                        <Link href="#0">
                                                            <img src={blog.path != "" ? blog.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/blog-thumb-1.jpg`} alt="" />
                                                        </Link>
                                                    </div>
                                                    <small>เขียนเมื่อ {blog.createdAt}</small>
                                                    <h3>
                                                        <Link to={`/gotoblog/${blog.id}`}>
                                                            {blog.title}
                                                        </Link>
                                                    </h3>
                                                </li>
                                            )
                                        }) : <Skeleton count={10} />
                                    }
                                </ul>
                                </div>
                                {/* /widget */}
                                {/* <div className="widget">
                                <div className="widget-title">
                                    <h4>Categories</h4>
                                </div>
                                <ul className="cats">
                                    <li>
                                    <a href="#">
                                        Food <span>(12)</span>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        Places to visit <span>(21)</span>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        New Places <span>(44)</span>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="#">
                                        Suggestions and guides <span>(31)</span>
                                    </a>
                                    </li>
                                </ul>
                                </div>
                                {/* /widget */}
                                {/* <div className="widget">
                                <div className="widget-title">
                                    <h4>Popular Tags</h4>
                                </div>
                                <div className="tags">
                                    <a href="#">Food</a>
                                    <a href="#">Bars</a>
                                    <a href="#">Cooktails</a>
                                    <a href="#">Shops</a>
                                    <a href="#">Best Offers</a>
                                    <a href="#">Transports</a>
                                    <a href="#">Restaurants</a>
                                </div>
                                </div> */}
                                {/* /widget */}
                            </aside>
                            {/* /aside */}
                            </div>
                            {/* /row */}
                    </> : <Skeleton count={20} />
                }
            </div>
            {/* /container */}
            </main>

    )
}

export default Blog;