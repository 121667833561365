import React from 'react'
import './../../assets/css/MobileNavbar.css'
import { FaHome } from "react-icons/fa";
import { LuPackageCheck } from "react-icons/lu";
import { FaCartArrowDown } from "react-icons/fa";
import { RiContactsFill } from "react-icons/ri";
import { FaLine } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function NavbarMobile() {
    return (
        <div className='container_Navbar_Mobile'>
            <Link to={`/`}>
                <FaHome />
                <p>หน้าหลัก</p>
            </Link>
            <Link to={`/products`}>
                <LuPackageCheck />
                <p>สินค้า</p>
            </Link>
            <Link to={`/carts`}>
                <FaCartArrowDown />
                <p>ตะกร้า</p>
            </Link>
            <Link to={`/account`}>
                <RiContactsFill/>
                <p>สมาชิก</p>
            </Link>
            {/* <Link>
                <FaLine/>
                <p>สอบถาม</p>
            </Link> */}
        </div>
    )
}

export default NavbarMobile;