/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { Link } from "react-router-dom";

const ProductCategory = (components) => {

    const [allProducts, setAllProducts] = useState(components.allProducts);
    const [allCategories, setAllCategories] = useState(components.allCategories);

    useEffect(() => {
        setAllProducts(components.allProducts);
        setAllCategories(components.allCategories);
    }, [components]);

    return (
        <div className="container margin_60_35">
            {/* <div className="main_title">
                <h2>หมวดหมู่สินค้า</h2>
                <span>หมวดหมู่สินค้า</span>
            </div> */}
            <div style={
                {
                    padding: "32px",
                    borderRadius: "7px 65px",
                    backgroundImage: "linear-gradient(90deg, #3236a4 10%, #6bbcea 90%)",
                    color: "white",
                    fontSize: "25px"
                }
            }>
                หมวดหมู่สินค้า
            </div>
            <div className={`row ${allCategories.length < 8 ? 'justify-content-center' : ''}`}>
                {
                    allCategories.map((category, index) => {
                        if (category.count > 0) {
                            return (
                                <div className="col-md-3 col-sm-12 p-2" key={index}>
                                    <button
                                        className="btn_1"
                                        style={{ height: '100%', width: '100%' }}
                                        onClick={(e) => {
                                            components.selectCategory(category.id)
                                        }}>
                                        {category.name}
                                    </button>
                                </div>
                            )
                        }
                    })
                }
            </div>
            <div className="row" style={{ textAlign: 'center' }}>
                {
                    allProducts.slice(0, 8).map((product, index) => {
                        return (
                            <div key={index} className="col-md-3 col-sm-12 p-2">
                                <div className="item">
                                    <div className="grid_item">
                                        <figure>
                                            <Link to={`/goto/${product.id}`}>
                                                <img
                                                    style={{ width: '290px', height: '290px' }}
                                                    src={product.path != null ? product.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                    data-src={product.path != null ? product.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                    alt=""
                                                />
                                            </Link>
                                        </figure>
                                        <Link to={`/goto/${product.id}`}>
                                            <h6>{product.name}</h6>
                                        </Link>
                                        <div className="price_box">
                                            <span className="new_price">{parseFloat(product.price).toLocaleString("en-US")}฿</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ProductCategory