import LoginAdmin from './LoginAdmin';
import Cookies from 'universal-cookie';
import '../../assets/css/account.css'

const Login = (components) => {
    return (
        <main className="bg_gray">
            <div className="container margin_30">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="box_account">
                            <h3 className="client">เข้าสู่ระบบผู้ดูแล</h3>
                            <div className="form_container">
                                <LoginAdmin {...components} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Login;