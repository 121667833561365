import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './../../assets/css/footer.css';
import visamastercardlogo from './../../assets/img/footer/png-transparent-visa-mastercard-logo-visa-mastercard-computer-icons-visa-text-payment-logo.png'
import dbdlogo from './../../assets/img/footer/dbd_registered-1-e1695724950393-300x124-1.png'

const NewFooter = (components) => {
    const [categories, setCategories] = useState([]);
    const [webInformation, setWebInformation] = useState({});

    useEffect(() => {
        setWebInformation(components.webInformation)
    }, [components]);

    return (
        <footer>
            <hr />
            <div className="contener">
                <div className="top_contener">
                <div className="about_us">
                    <div className="about_logo_and_`map`">
                    <div className="logo text-center">
                        <Link to='/'>
                            <img src={`${process.env.REACT_APP_BASE_URL}/img/logo_none_text.png`} alt="" width={150} height={150} style={{ borderRadius: "5px" }} className="fluid" />
                        </Link>
                    </div>
                    {/* <div className="map" style={{ marginTop: "10px"}}>
                        <img
                            src="https://freepngimg.com/thumb/map/66960-maps-google-computer-icons-free-png-hq.png"
                            alt="map"
                            width={200}
                            height={200}
                        />
                    </div> */}
                    </div>
                    <div className="about">
                    <h3>เกี่ยวกับเรา</h3>
                    <p>
                        {/* <strong>กรุงเทพเคมี.com</strong> (กรุงเทพเคมี.com)
                        <br />
                        เราเป็นผู้นำเข้าและจัดจำหน่ายสารเคมีสำหรับผลิตทำสินค้า<br/>เช่น
                        เคมีอาหาร เคมีเครื่องสำอาง และน้ำมันหอมระเหย
                        ที่กำลังเป็นที่นิยมอยู่ในขณะนี้เคมีอุตสาหกรรมสำหรับการผลิตสินค้าต่างๆ<br/>ในอุตสาหกรรมทั่วไป กรุงเทพเคมี
                        เราขอเป็นเบื้องหลังของความสำเร็จของท่าน */}
                        {
                            webInformation.about
                        }
                    </p>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6 text-center">
                                    <img className="img-fluid" src={visamastercardlogo} style={
                                        {
                                            width: '100px',
                                            marginBottom: '1rem'
                                        }
                                    } />
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 text-center">
                                    <img className="img-fluid" src={dbdlogo} style={
                                        {
                                            width: '100px',
                                            marginBottom: '1rem'
                                        }
                                    } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="category">
                    <h3>หมวดหมู่</h3>
                    <div>
                    <p>
                        <a href="">Acidity regulator : สารควบคุมความเป็นกรด</a>
                    </p>
                    <p>
                        <a href="">Food coloring สีผสมอาหาร</a>
                    </p>
                    </div>
                </div>
                <div className="contact_us">
                    <h3>ติดต่อเรา</h3>
                    <div>
                    <div className="contacts">
                        <svg
                        fill="#008080"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 495.398 495.398"
                        xmlSpace="preserve"
                        stroke="#008080"
                        >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            stroke="#CCCCCC"
                            strokeWidth="3.9631840000000005"
                        >
                            {" "}
                            <g>
                            {" "}
                            <g>
                                {" "}
                                <g>
                                {" "}
                                <path d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391 v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158 c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747 c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z" />{" "}
                                <path d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401 c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79 c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z" />{" "}
                                </g>{" "}
                            </g>{" "}
                            </g>{" "}
                        </g>
                        <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                            {" "}
                            <g>
                                {" "}
                                <g>
                                {" "}
                                <path d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391 v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158 c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747 c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z" />{" "}
                                <path d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401 c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79 c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z" />{" "}
                                </g>{" "}
                            </g>{" "}
                            </g>{" "}
                        </g>
                        </svg>
                        <p style={{ paddingLeft: '10px' }}>
                            {/* 73 ถนนอยู่เย็น (รามอินทรา 34) แขวงลาดพร้าว เขตลาดพร้าว
                            กรุงเทพมหานคร 10230 */}
                            {
                                <Link to={webInformation.map_url} target="_blank" style={
                                    {
                                        color: 'white'
                                    }
                                }>
                                    {webInformation.address}
                                </Link>
                            }
                        </p>
                    </div>
                    <div className="contacts">
                        <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                            d="M14.05 6C15.0268 6.19057 15.9244 6.66826 16.6281 7.37194C17.3318 8.07561 17.8095 8.97326 18 9.95M14.05 2C16.0793 2.22544 17.9716 3.13417 19.4163 4.57701C20.8609 6.01984 21.7721 7.91101 22 9.94M18.5 21C9.93959 21 3 14.0604 3 5.5C3 5.11378 3.01413 4.73086 3.04189 4.35173C3.07375 3.91662 3.08968 3.69907 3.2037 3.50103C3.29814 3.33701 3.4655 3.18146 3.63598 3.09925C3.84181 3 4.08188 3 4.56201 3H7.37932C7.78308 3 7.98496 3 8.15802 3.06645C8.31089 3.12515 8.44701 3.22049 8.55442 3.3441C8.67601 3.48403 8.745 3.67376 8.88299 4.05321L10.0491 7.26005C10.2096 7.70153 10.2899 7.92227 10.2763 8.1317C10.2643 8.31637 10.2012 8.49408 10.0942 8.64506C9.97286 8.81628 9.77145 8.93713 9.36863 9.17882L8 10C9.2019 12.6489 11.3501 14.7999 14 16L14.8212 14.6314C15.0629 14.2285 15.1837 14.0271 15.3549 13.9058C15.5059 13.7988 15.6836 13.7357 15.8683 13.7237C16.0777 13.7101 16.2985 13.7904 16.74 13.9509L19.9468 15.117C20.3262 15.255 20.516 15.324 20.6559 15.4456C20.7795 15.553 20.8749 15.6891 20.9335 15.842C21 16.015 21 16.2169 21 16.6207V19.438C21 19.9181 21 20.1582 20.9007 20.364C20.8185 20.5345 20.663 20.7019 20.499 20.7963C20.3009 20.9103 20.0834 20.9262 19.6483 20.9581C19.2691 20.9859 18.8862 21 18.5 21Z"
                            stroke="#008080"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />{" "}
                        </g>
                        </svg>
                        <p style={{ paddingLeft: '10px' }}> {
                            webInformation.phone_number != undefined ? webInformation.phone_number.split(",").map((number, idx) => {
                                const show_number = webInformation.phone_number.split(",").length == (idx + 1) ? `${number}` : `${number}, `
                                return (
                                    <>
                                        <Link to={`tel:${number}`} style={
                                            {
                                                color: 'white'
                                            }
                                        }>{show_number}</Link>
                                    </>
                                )
                            }) : ""
                        }</p>
                    </div>
                    <div className="contacts">
                        <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                            d="M14.5 9.50012H9.5M3.02832 10.0001L10.2246 14.8167C10.8661 15.2444 11.1869 15.4582 11.5336 15.5413C11.8399 15.6147 12.1593 15.6147 12.4657 15.5413C12.8124 15.4582 13.1332 15.2444 13.7747 14.8167L20.971 10.0001M10.2981 4.06892L4.49814 7.71139C3.95121 8.05487 3.67775 8.2266 3.4794 8.45876C3.30385 8.66424 3.17176 8.90317 3.09111 9.16112C3 9.45256 3 9.77548 3 10.4213V16.8001C3 17.9202 3 18.4803 3.21799 18.9081C3.40973 19.2844 3.71569 19.5904 4.09202 19.7821C4.51984 20.0001 5.0799 20.0001 6.2 20.0001H17.8C18.9201 20.0001 19.4802 20.0001 19.908 19.7821C20.2843 19.5904 20.5903 19.2844 20.782 18.9081C21 18.4803 21 17.9202 21 16.8001V10.4213C21 9.77548 21 9.45256 20.9089 9.16112C20.8282 8.90317 20.6962 8.66424 20.5206 8.45876C20.3223 8.2266 20.0488 8.05487 19.5019 7.71139L13.7019 4.06891C13.0846 3.68129 12.776 3.48747 12.4449 3.41192C12.152 3.34512 11.848 3.34512 11.5551 3.41192C11.224 3.48747 10.9154 3.68129 10.2981 4.06892Z"
                            stroke="#008080"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />{" "}
                        </g>
                        </svg>
                        <p style={{ paddingLeft: '10px' }}> {
                            <Link to={`mailto:${webInformation.email}`} style={
                                {
                                    color: 'white'
                                }
                            }>
                                {webInformation.email}
                            </Link>
                        }</p>
                    </div>
                    <div className="contacts">
                        <svg
                        fill="#008080"
                        width="29px"
                        height="29px"
                        viewBox="-5.5 0 32 32"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                            {" "}
                            <title>line</title>{" "}
                            <path d="M10.656 5.938c5.938 0 10.719 3.875 10.719 8.688 0 2.344-1.156 4.406-2.969 6.031-2.938 2.906-8 5.844-8.531 5.625-0.875-0.344 0.656-2.219 0.031-3.031-0.094-0.125-0.438-0.094-1.063-0.188-5.156-0.688-8.844-4.094-8.844-8.469 0-4.813 4.75-8.656 10.656-8.656zM4.563 17.5h1.813c0.313 0 0.5-0.25 0.5-0.563 0-0.219-0.156-0.5-0.563-0.5h-1.469c-0.125 0-0.125-0.125-0.125-0.563v-3.156c0-0.281-0.188-0.563-0.531-0.563-0.313 0-0.531 0.25-0.531 0.563v3.813c0 0.844 0.406 0.969 0.906 0.969zM8.656 17.063v-4.344c0-0.281-0.219-0.563-0.563-0.563-0.281 0-0.531 0.25-0.531 0.563v4.344c0 0.281 0.219 0.5 0.563 0.5 0.281 0 0.531-0.219 0.531-0.5zM13.781 16.469v-3.813c0-0.281-0.219-0.5-0.563-0.5-0.25 0-0.531 0.156-0.531 0.5v2.75l-1.813-2.531c-0.25-0.438-0.563-0.719-0.938-0.719-0.469 0-0.5 0.375-0.5 0.906v4c0 0.281 0.219 0.5 0.531 0.5 0.281 0 0.531-0.188 0.531-0.5v-2.844l1.813 2.531c0.406 0.531 0.5 0.813 1 0.813 0.344 0 0.469-0.313 0.469-1.094zM17.281 14.313h-1.594v-0.906c0-0.094 0.031-0.219 0.188-0.219h1.406c0.344 0 0.563-0.188 0.563-0.531 0-0.406-0.313-0.531-0.594-0.531h-1.813c-0.563 0-0.844 0.375-0.844 0.875v3.531c0 0.625 0.25 0.969 0.844 0.969h1.844c0.406 0 0.563-0.25 0.563-0.563 0-0.406-0.313-0.531-0.563-0.531h-1.375c-0.125 0-0.219-0.094-0.219-0.188v-0.875h1.656c0.406 0 0.469-0.313 0.469-0.531 0-0.313-0.25-0.5-0.531-0.5z" />{" "}
                        </g>
                        </svg>
                        <p style={{ paddingLeft: '10px' }}> {
                            <Link to={`https://line.me/ti/p/~${webInformation.line}`} target="_blank" style={
                                {
                                    color: 'white'
                                }
                            }>
                                {webInformation.line}
                            </Link>
                        }</p>
                    </div>
                    </div>
                </div>
                <div className="link">
                    <h3>ลิงก์ด่วน</h3>
                    <div className="link_btn">
                    <p>
                        <a href="#">About us</a>
                    </p>
                    <p>
                        <a href="#">Faq</a>
                    </p>
                    <p>
                        <a href="#">Help</a>
                    </p>
                    <p>
                        <a href="#">My account</a>
                    </p>
                    <p>
                        <a href="#">Blog</a>
                    </p>
                    <p>
                        <a href="#">Contacts</a>
                    </p>
                    </div>
                </div>
                </div>
            </div>
            <hr />
            <div className="buttom_contener">
                <div className="copyright">
                <p>
                    <a href="#0">
                    <strong>Terms and conditions</strong>
                    </a>
                </p>
                <hr />
                <p>
                    <a href="#0">
                    <strong>Privacy</strong>
                    </a>
                </p>
                <hr />
                <p>
                    <strong>© 2023 กรุงเทพเคมี All Rights Reserved</strong>
                </p>
                </div>
            </div>
        </footer>
    )
}

export default NewFooter;