import '../../assets/css/cart.css'
import { createRef, useEffect, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import toast, { Toaster } from 'react-hot-toast';
const cookies = new Cookies(null, { path: '/' });

const Carts = (components) => {
    const notify = (msg, options) => toast(msg, {...options});
    const carts = components.carts;
    const totals = components.totals;
    const navigate = useNavigate();

    const cartsRefs = useMemo(
        () => carts.map(()=> createRef()), 
        [carts.join(',')]
    );

    useEffect(() => {
        if(cookies.get('token') == undefined){
            return navigate('/')
        }
    }, [])

    const updateCart = async () => {
        const reg_number = /^\d+$/;
        let onlyNumber = true;

        await Promise.all(cartsRefs.map((ref) => {
            if(!reg_number.test(ref.current.value))
            {
                onlyNumber = false;
            }
        }));
        
        if(onlyNumber)
        {
            let sendUpdateData = []

            await Promise.all(carts.map((cart, index) => {
                sendUpdateData.push({
                    product_id: cart.product_id,
                    product_price_id: cart.product_price_id,
                    quantity: isNaN(parseInt(cartsRefs[index].current.value)) ? 1 : parseInt(cartsRefs[index].current.value)
                })
            }))

            axios.post(`${process.env.REACT_APP_API_URL}/carts/update`, {
                data: JSON.stringify(sendUpdateData)
            }, {
                headers: {
                    token: cookies.get('token')
                }
            }).then(async (response) => {
                if(response.data.message == 'Success')
                {
                    notify("อัพเดทเรียบร้อยแล้ว", {
                        duration: 3000,
                        style: {
                            color: "white",
                            backgroundColor: "#004dda"
                        },
                        icon: '✔️',
                        position: 'bottom-center',
                        iconTheme: {
                            primary: '#000',
                            secondary: '#fff',
                        },
                    });

                    const sortCartsByProductCreated = response.data.data.carts.sort(function(a, b){
                        var d1 = new Date(a.productCreatedAt);
                        var d2 = new Date(b.productCreatedAt);
                        return d1-d2;
                      });
                    const sortCartsByPriceCreated = sortCartsByProductCreated.sort(function(a, b){
                        var d1 = new Date(a.priceCreatedAt);
                        var d2 = new Date(b.priceCreatedAt);
                        return d1-d2;
                    })
                    
                    components.setCarts(sortCartsByPriceCreated);
                    components.setTotalsInCarts(response.data.data.totals);
                    window.location.reload(false);
                }
                else
                {
                    notify(response.data.message, {
                        duration: 5000,
                        style: {
                            color: "white",
                            backgroundColor: "red"
                        },
                        icon: '❌',
                        position: 'bottom-center',
                        iconTheme: {
                            primary: '#000',
                            secondary: '#fff',
                        },
                    });
                }
            })
        }
        else
        {
            notify("กรุณากรอกเฉพาะตัวเลข", {
                duration: 5000,
                style: {
                    color: "white",
                    backgroundColor: "red"
                },
                icon: '❌',
                position: 'bottom-center',
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
            });
        }
    }

    return (
        <>
        
            <main className="bg_gray">
                <Toaster />
                <div className="container margin_30">
                <div className="page_header">
                    {/* <div className="breadcrumbs">
                    <ul>
                        <li>
                        <a href="#">Home</a>
                        </li>
                        <li>
                        <a href="#">Category</a>
                        </li>
                        <li>Page active</li>
                    </ul>
                    </div> */}
                    <h1>ตะกร้าสินค้าของคุณ</h1>
                </div>
                {/* /page_header */}
                <table className="table table-striped cart-list">
                    <thead>
                    <tr>
                        <th>สินค้า</th>
                        <th>ราคา</th>
                        <th>จำนวน</th>
                        <th>รวม</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            carts.length > 0 ? carts.map((cart, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="thumb_cart">
                                                {/* <Link goto={`/goto/${cart.product_id}`}> */}
                                                    <img
                                                        src={cart.product_image != null ? cart.product_image.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                        data-src={cart.product_image != null ? cart.product_image.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                        className="lazy"
                                                        alt="Image"
                                                    />
                                                {/* </Link> */}
                                            </div>
                                            <span className="item_cart">
                                                <Link to={`/goto/${cart.product_id}`}>
                                                    {cart.product_name}&nbsp;
                                                    <small>
                                                        (ตัวเลือก: {cart.product_price_name})
                                                    </small>
                                                </Link>
                                            </span>
                                        </td>
                                        <td>
                                            <strong>{cart.price_per_each.toLocaleString("en-US")}฿</strong>
                                        </td>
                                        <td>
                                            <div className="numbers-row">
                                                <input
                                                    ref={cartsRefs[index]}
                                                    type="text"
                                                    defaultValue={cart.quantity}
                                                    id="quantity_1"
                                                    className="qty2"
                                                    name="quantity_1"
                                                    autoComplete="off"
                                                />
                                                {/* <div className="inc button_inc" onClick={() => {

                                                }}>+</div>
                                                <div className="dec button_inc">-</div> */}
                                            </div>
                                        </td>
                                        <td>
                                            <strong>{(cart.quantity * cart.price_per_each).toLocaleString("en-US")}฿</strong>
                                        </td>
                                        <td className="options">
                                            <Link onClick={() => {
                                                components.removeProductInCart(cart.product_id, cart.product_price_id);
                                            }}>
                                                <i className="ti-trash" />
                                            </Link>
                                        </td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan={5} className='text-center'>ไม่มีสินค้าในตะกร้า</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <div className="row add_top_30 flex-sm-row-reverse cart_actions">
                    <div className="col-sm-4 text-end">
                        {
                            carts.length > 0 && <button type="button" className="btn_1 gray" onClick={updateCart}>
                                อัพเดทตะกร้า
                            </button>
                        }
                        
                    </div>
                    <div className="col-sm-8">
                    {/* <div className="apply-coupon">
                        <div className="form-group">
                        <div className="row g-2">
                            <div className="col-md-6">
                            <input
                                type="text"
                                name="coupon-code"
                                defaultValue=""
                                placeholder="Promo code"
                                className="form-control"
                            />
                            </div>
                            <div className="col-md-4">
                            <button type="button" className="btn_1 outline">
                                Apply Coupon
                            </button>
                            </div>
                        </div>
                        </div>
                    </div> */}
                    </div>
                </div>
                {/* /cart_actions */}
                </div>
                {/* /container */}
                {
                    carts.length > 0 && <div className="box_cart">
                        <div className="container">
                            <div className="row justify-content-end">
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <ul>
                                {/* <li>
                                    <span>Subtotal</span> {totals}฿
                                </li> */}
                                {/* <li>
                                    <span>Shipping</span> $7.00
                                </li> */}
                                <li>
                                    <span>ยอดรวมสุทธิ</span> {totals.toLocaleString("en-US")}฿
                                </li>
                                </ul>
                                <Link to="/checkout" className="btn_1 full-width cart">
                                    ดำเนินการชำระเงิน
                                </Link>
                            </div>
                            </div>
                        </div>
                    </div>
                }
                
                {/* /box_cart */}
            </main>
            {/*/main*/}
        </>
    )
}

export default Carts;