import { useState, useEffect, useRef } from "react";
import SimpleMDE from "react-simplemde-editor";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import Cookies from 'universal-cookie';
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select'
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'

// CSS
import '../../../assets/css/mde.css'
import '../../../assets/css/listing.css'

const Product = (components) => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: '/' });
    const fileTypes = ["JPG", "PNG"];

    const product_codeRef = useRef(null);
    const product_nameRef = useRef(null);
    const option_nameRef = useRef(null);
    const option_priceRef = useRef(null);
    const option_stockRef = useRef(null);

    const price_name = useRef(null);
    const price_price = useRef(null);
    const price_stock = useRef(null);

    const [product_description, setProduct_description] = useState("");
    const [product, setProduct] = useState(undefined);
    const [productImg, setProductImg] = useState([]);
    const [productPrice, setProductPrice] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);

    const [selectedOptionPriceId, setSelectedOptionPriceId] = useState(0);

    const [allCategories, setAllCategories] = useState([]);
    const [defaultIndexAllCategories, setDefaultIndexAllCategories] = useState(0);

    const onChangeDescription = (value) => {
        setProduct_description(value);
    };

    useEffect(() => {
        const find_product = components.allProducts.find((element) => element.id == components.productId )
        if(find_product != undefined)
        {
            axios.get(`${process.env.REACT_APP_API_URL}/products/id/${find_product.id}`).then((response) => {
                if(response.data.message == 'FOUND')
                {
                    setProductPrice(response.data.priceOfProduct);
                    setProductImg(response.data.imageOfProduct);
                    
                    response.data.priceOfProduct[0] != undefined && setSelectedOptionPriceId(response.data.priceOfProduct[0].value);
                    price_name.current.value = response.data.priceOfProduct[0] != undefined ? response.data.priceOfProduct[0].label : '';
                    price_price.current.value = response.data.priceOfProduct[0] != undefined ? response.data.priceOfProduct[0].price : '';
                    price_stock.current.value = response.data.priceOfProduct[0] != undefined ? response.data.priceOfProduct[0].stock : '';
                    // setSelectedOptionPrice(response.data.priceOfProduct[0])
                }
            });
            
            setProduct(find_product);
            setProduct_description(find_product.description);

            const updateCategories = [];
            components.categories.map((category, index) => {
                updateCategories.push({
                    label: category.name,
                    value: category.id
                });
                
                if(category.id == find_product.category_id)
                {
                    setDefaultIndexAllCategories(index);
                    setSelectedCategory({
                        label: category.name,
                        value: category.id
                    });
                }
            })
            setAllCategories(updateCategories);
        }
        
    }, [components.productId, components.allProducts]);

    const addOptionOfProduct = () => {
        if(option_nameRef.current.value == '' || option_nameRef.current.value == undefined)
        {
            components.notify_error('ชื่อตัวเลือกของสินค้า ไม่สามารถเว้นว่างไว้ได้');
            return;
        }

        if(isNaN(parseFloat(option_priceRef.current.value)))
        {
            components.notify_error('กรุณาใส่ราคาของตัวเลือกเป็นตัวเลขเท่านั้น และ จุดทศนิยมเท่านั้น !');
            return;
        }

        if(isNaN(parseInt(option_stockRef.current.value)))
        {
            components.notify_error('กรุณาใส่สต็อคของตัวเลือกเป็นตัวเลขเท่านั้น !');
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/products/addPrice`, {
            product_id: product.id,
            name: option_nameRef.current.value,
            price: parseFloat(option_priceRef.current.value),
            stock: parseInt(option_stockRef.current.value)
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success(`เพิ่มตัวเลือกสินค้าเรียบร้อยแล้ว`)
                components.reloadProducts();
            }
            else
            {
                components.notify_error(`เพิ่มตัวเลือกสินค้าไม่สำเร็จ (${response.data.message})`);
            }
        })
    }

    const handleChange = (file) => {
        const formData = new FormData();
        const product_id = product.id
        formData.append('file', file);

        axios.post(`${process.env.REACT_APP_API_URL}/upload/productImg`, formData, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                axios.post(`${process.env.REACT_APP_API_URL}/products/insertpathimg`, {
                    product_id: product_id,
                    path_img: response.data.path
                }, {
                    headers: {
                        token: cookies.get('token')
                    }
                }).then((response) => {
                    if(response.data.message == 'OK')
                    {
                        components.notify_success(`อัพโหลดรูปภาพเรียบร้อยแล้ว`)
                        components.reloadProducts();
                    }
                    else
                    {
                        components.notify_error(`อัพโหลดรูปภาพไม่สำเร็จ (${response.data.message})`)
                    }
                })
            }
            else
            {
                components.notify_error(`อัพโหลดรูปภาพไม่สำเร็จ (${response.data.message})`)
            }
        })
    };

    const removeImg = (img_id) => {
        axios.post(`${process.env.REACT_APP_API_URL}/products/removeImage`, {
            image_id: img_id
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success('ลบรูปภาพเรียบร้อยแล้ว')
                components.reloadProducts();
            }
            else
            {
                components.notify_error(`เกิดข้อผิดพลาดในการลบรูปภาพ ${response.data.message}`);
            }
        })
    }

    const editProductDetail = (event) => {
        event.preventDefault();
        if(product_codeRef.current.value == '' || product_codeRef.current.value == undefined || product_codeRef.current.value == null)
        {
            return components.notify_error('Code หรือ Model ไม่สามารถทำเป็นช่องว่างได้');
        }

        if(product_nameRef.current.value == '' || product_nameRef.current.value == undefined || product_nameRef.current.value == null)
        {
            return components.notify_error('ชื่อสินค้า ไม่สามารถทำเป็นช่องว่างได้');
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}/products/editProduct`, {
            id: product.id,
            code: product_codeRef.current.value,
            name: product_nameRef.current.value,
            description: product_description,
            category_id: selectedCategory.value
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success('อัพเดทสินค้าเรียบร้อยแล้ว');
                components.reloadProducts();
                components.reloadCategories();
            }
            else
            {
                components.notify_error(`เกิดข้อผิดพลาดในการอัพเดทสินค้า ${response.data.message}`);
            }
        })
    }

    const deleteProduct = () => {
        Swal.fire({
            title: "ยืนยันหรือไม่ ?",
            text: "แน่ใจที่จะลบสินค้าใช่หรือไม่",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "ใช่, ลบเลย!",
            cancelButtonText: "ไม่, ยกเลิก!",
            dangerMode: true,
        }).then(function(result) {
            if(result.isConfirmed)
            {
                axios.post(`${process.env.REACT_APP_API_URL}/products/deleteProduct`, {
                    product_id: product.id
                }, {
                    headers: {
                        token: cookies.get('token')
                    }
                }).then((response) => {
                    if(response.data.message == 'OK')
                    {
                        components.notify_success('ลบสินค้าเรียบร้อยแล้ว');
                        components.reloadProducts();
                        components.reloadCategories();
                        components.setPage('all_products');
                    }
                    else
                    {
                        components.notify_error(`เกิดข้อผิดพลาดในการลบสินค้า ${response.data.message}`);
                    }
                });
            }
        })
    }

    const changeCategory = (selectedOption) => {
        setSelectedCategory(selectedOption);
    }

    const editPriceOption = () => {
        const selected = selectedOptionPriceId;
        const name = price_name.current.value;
        const price = price_price.current.value;
        const stock = price_stock.current.value;

        if(name == '' || name == undefined)
        {
            components.notify_error('ชื่อตัวเลือกของสินค้า ไม่สามารถเว้นว่างไว้ได้');
            return;
        }

        if(isNaN(parseFloat(price)))
        {
            components.notify_error('กรุณาใส่ราคาของตัวเลือกเป็นตัวเลขเท่านั้น และ จุดทศนิยมเท่านั้น !');
            return;
        }

        if(isNaN(parseInt(stock)))
        {
            components.notify_error('กรุณาใส่สต็อคของตัวเลือกเป็นตัวเลขเท่านั้น !');
            return;
        }
        
        axios.post(`${process.env.REACT_APP_API_URL}/products/updatePriceOption`, {
            id: selected,
            name: name,
            price: parseFloat(price),
            stock: parseInt(stock)
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success('อัพเดท Option เรียบร้อยแล้ว');
            }
            else
            {
                components.notify_error('อัพเดท Option ไม่สำเร็จ');
            }
        })
    }

    const removePriceOption = () => {
        const selected = selectedOptionPriceId;
        axios.post(`${process.env.REACT_APP_API_URL}/products/removePriceOption`, {
            id: selected
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success('ลบ Option เรียบร้อยแล้ว');
                components.reloadProducts();
            }
            else
            {
                components.notify_error('ลบ Option ไม่สำเร็จ');
            }
        })
    }

    if(product != undefined)
        return (
            <div>
                <Toaster />
                <form onSubmit={editProductDetail}>
                    <div className="row">
                        <div className="col-md-4 col-sm-12 my-3">
                            <FileUploader name="file" types={fileTypes} handleChange={handleChange} />
                        </div>
                        <div className="col-md-8 col-sm-12 mb-3">
                            <div className="card">
                                <div className="card-header text-center text-white bg-primary">
                                    <i className="ti-image"></i> รูปภาพ
                                </div>
                                <div className="card-body">
                                    <div className="row small-gutters">
                                        {
                                            productImg.length > 0 ? productImg.map((img, index) => {
                                                return (
                                                    <div className="col-6 col-md-6" key={index}>
                                                        <div className="grid_item">
                                                            <figure>
                                                                <img
                                                                    className="img-fluid lazy"
                                                                    src={img.path != null ? img.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                                    data-src={img.path != null ? img.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                                    alt=""
                                                                />
                                                            </figure>
                                                            <ul>
                                                                <li>
                                                                    <a
                                                                        className="tooltip-1 bg-danger text-white"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="left"
                                                                        title="ลบรูปภาพ"
                                                                        onClick={() => {
                                                                            removeImg(img.id)
                                                                        }}
                                                                    >
                                                                        <i className="ti-trash" />
                                                                        <span>ลบรูปภาพ</span>
                                                                    </a>
                                                                    
                                                                </li>
                                                                
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )
                                            }) : <div className="text-center">
                                                    สินค้าชิ้นนี้ยังไม่มีรูปภาพ
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="form-group">
                                <label for="product_code">
                                    Code หรือ Model สินค้า:
                                </label>
                                <input
                                    ref={product_codeRef}
                                    type="text"
                                    className="form-control"
                                    name="product_code"
                                    id="product_code"
                                    placeholder="Code หรือ Model สินค้า"
                                    defaultValue={product.code}
                                />
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12">
                            <div className="form-group">
                                <label for="product_name">
                                    ชื่อสินค้า:
                                </label>
                                <input
                                    ref={product_nameRef}
                                    type="text"
                                    className="form-control"
                                    name="product_name"
                                    id="product_name"
                                    placeholder="ชื่อสินค้า"
                                    defaultValue={product.name}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className="col-md-4 col-sm-12 mb-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>
                                            ตัวเลือกสินค้าที่มีตอนนี้:
                                        </label>
                                        {
                                            productPrice.length > 0 ? <Select defaultValue={productPrice[0]} options={productPrice} onChange={(e) => {
                                                setSelectedOptionPriceId(e.value);
                                                price_name.current.value = e.label;
                                                price_price.current.value = e.price;
                                                price_stock.current.value = e.stock;
                                            }} /> : <Skeleton count="2"/>
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label for="price_name">
                                            ชื่อตัวเลือก:
                                        </label>
                                        <input
                                            name="price_name"
                                            id="price_name"
                                            ref={price_name}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label for="price_price">
                                            ราคา:
                                        </label>
                                        <input
                                            name="price_price"
                                            id="price_price"
                                            ref={price_price}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label for="price_stock">
                                            จำนวน Stock:
                                        </label>
                                        <input
                                            name="price_stock"
                                            id="price_stock"
                                            ref={price_stock}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <button type="button" name="submitEditPrice" id="submitEditPrice" className="btn_1 full-width" onClick={editPriceOption}>
                                        แก้ไขตัวเลือกสินค้า
                                    </button>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <button type="button" name="submitEditPrice" id="submitEditPrice" className="btn_1 red full-width" onClick={removePriceOption}>
                                        ลบตัวเลือกสินค้า
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label for="option_name">
                                    เพิ่มตัวเลือกสินค้า:
                                </label>
                                <input
                                    ref={option_nameRef}
                                    type="text"
                                    className="form-control"
                                    name="option_name"
                                    id="option_name"
                                    placeholder="ชื่อตัวเลือกสินค้า"
                                />
                                <input
                                    ref={option_priceRef}
                                    type="text"
                                    className="form-control mt-2"
                                    name="option_price"
                                    id="option_price"
                                    placeholder="ราคาของตัวเลือก"
                                />
                                <input
                                    ref={option_stockRef}
                                    type="text"
                                    className="form-control mt-2"
                                    name="option_stock"
                                    id="option_stock"
                                    placeholder="สต็อคของตัวเลือก"
                                />
                                <button type="button" className="btn_1 full-width mt-2" onClick={() => {
                                    addOptionOfProduct();
                                }}>
                                    เพิ่มตัวเลือกสินค้า
                                </button>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <label className="col-12">
                                หมวดหมู่
                            </label>
                            <div className="col-12">
                                <Select defaultValue={allCategories[defaultIndexAllCategories]} options={allCategories} onChange={changeCategory} />
                            </div>
                        </div>
                        <div className="col-12">
                            <label>คำอธิบาย:</label>
                            <SimpleMDE
                                value={product_description}
                                onChange={onChangeDescription}
                            />
                        </div>
                        <hr/>
                        <div className="col-md-6 col-sm-12">
                            <button type="submit" className="btn_1 full-width">
                                <i className="ti-pencil"></i> แก้ไขรายละเอียดสินค้า
                            </button>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <button type="button" className="btn_1 red full-width" onClick={deleteProduct}>
                                <i className="ti-trash"></i> ลบสินค้า
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
}

export default Product;