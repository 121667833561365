import axios from "axios";
import { useEffect, useState, useRef } from "react";
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';

const Account = () => {
    const cookies = new Cookies(null, { path: '/' });
    const notify = (msg, options) => toast(msg, {...options});

    const notify_error = (msg) => {
        notify(msg, {
            duration: 5000,
            style: {
                color: "white",
                backgroundColor: "red"
            },
            icon: '❌',
            position: 'bottom-center',
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
        });
    }

    const notify_success = (msg) => {
        notify(msg, {
            duration: 3000,
            style: {
                color: "white",
                backgroundColor: "#004dda"
            },
            icon: '✔️',
            position: 'bottom-center',
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
        });
    }

    const [enableChangePassword, setEnableChangePassword] = useState(true);
    const [enableChangeProfile, setEnableChangeProfile] = useState(true);

    const old_passwordRef = useRef(null);
    const new_passwordRef = useRef(null);
    const new_passwordConfirm = useRef(null);

    const firstnameRef = useRef(null);
    const lastnameRef = useRef(null);
    const phoneNumberRef = useRef(null);

    const [loaded, setLoaded] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState([]);
    const [firstname, setFirstname] = useState([]);
    const [lastname, setLastname] = useState([]);

    const [allAddress, setAllAddress] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/members/getprofile`, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.length > 0)
            {
                const data = response.data[0];
                setPhoneNumber(data.phone);
                setFirstname(data.firstname);
                setLastname(data.lastname);
                setLoaded(true);
            }
            else
            {
                notify_error('ดึงข้อมูลผู้ใช้งานไม่สำเร็จ');
            }
        });

        reloadAddress();
    }, []);

    const reloadAddress = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/members/getAddress`, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'success')
            {
                setAllAddress(response.data.address);
            }
            else
            {
                notify_error(`เกิดข้อผิดพลาดในการดึงที่อยู่ (${response.data.message})`);
            }
        });
    }

    const changePasswordSubmit = async (event) => {
        event.preventDefault();
        if(!enableChangePassword)
        {
            return;
        }
        
        if(!old_passwordRef.current.value || !new_passwordRef.current.value)
        {
            return notify_error('กรุณากรอกรหัสผ่านใหม่ และ รหัสผ่านเก่า ให้ครบถ้วน');
        }

        if(new_passwordRef.current.value.length <= 3)
        {
            return notify_error('รหัสผ่านใหม่จำเป็นต้องมีมากกว่า 4 ตัว');
        }

        if(new_passwordRef.current.value != new_passwordConfirm.current.value)
        {
            return notify_error('รหัสผ่านใหม่ของคุณไม่ตรงกัน กรุณาตรวจสอบอีกครั้ง');
        }

        setEnableChangePassword(false);
        axios.post(`${process.env.REACT_APP_API_URL}/members/changepassword`, {
            old_password: old_passwordRef.current.value,
            new_password: new_passwordRef.current.value
        }, {
            headers: {
                token: cookies.get('token') || null
            }
        }).then((response) => {
            if(response.data.message == 'Success')
            {
                notify_success('เปลี่ยนรหัสผ่านเรียบร้อยแล้ว');
            }
            else
            {
                notify_error(`เปลี่ยนรหัสผ่านไม่สำเร็จ (${response.data.message})`);
            }

            setEnableChangePassword(true);
        })
    }

    const changeProfile = async (event) => {
        event.preventDefault();
        if(!enableChangeProfile)
        {
            return;
        }
        
        if(phoneNumberRef.current.value == '' || firstnameRef.current.value == '' || lastnameRef.current.value == '')
        {
            return notify_error('กรุณากรอกข้อมูลให้ครบทุกช่อง');
        }

        if(firstnameRef.current.value == firstname && lastnameRef.current.value == lastname && phoneNumberRef.current.value == phoneNumber)
        {
            return notify_error('ข้อมูลของคุณเหมือนเดิม กรุณากรอกข้อมูลใหม่อีกครั้ง');
        }

        setEnableChangeProfile(false);
        axios.post(`${process.env.REACT_APP_API_URL}/members/changeProfile`, {
            firstname: firstnameRef.current.value,
            lastname: lastnameRef.current.value,
            phoneNumber: phoneNumberRef.current.value
        }, {
            headers: {
                token: cookies.get('token') || null
            }
        }).then((response) => {
            if(response.data.message == 'success' && parseInt(response.data.changedRows) > 0)
            {
                notify_success('อัพเดทข้อมูลเรียบร้อยแล้ว');
                setFirstname(firstnameRef.current.value);
                setLastname(lastnameRef.current.value);
                setPhoneNumber(phoneNumberRef.current.value);
            }
            else
            {
                notify_error(`เกิดข้อผิดพลาด (${response.data.message})`)
            }

            setEnableChangeProfile(true);
        })
    }

    const deleteAddress = (address_id) => {
        axios.post(`${process.env.REACT_APP_API_URL}/members/deleteAddress`, {
            id: address_id
        }, {
            headers: {
                token: cookies.get('token') || null
            }
        }).then((response) => {
            if(response.data.message == 'success')
            {
                notify_success('ลบที่อยู่เรียบร้อยแล้ว...');
            }
            else
            {
                notify_error(`ลบที่อยู่ไม่สำเร็จ (${response.data.message})`);
            }

            reloadAddress();
        })
    }
    
    return (
        <main className="bg_gray">
            <Toaster />
            <div className="container margin_30">
                {
                    loaded ? <div className="row justify-content-center">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="box_account">
                            <div className="form_container" style={{
                                borderRadius: "15px"
                            }}>
                                <h3 className="client">จัดการ Account</h3>
                                <hr/>
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                                        <div className="form_container" style={{
                                            borderRadius: "15px"
                                        }}>
                                            <i className="ti-user"></i> ข้อมูลส่วนตัว
                                            <hr/>
                                            <form onSubmit={changeProfile}>
                                            <div className="form-group">
                                                    <input
                                                    ref={firstnameRef}
                                                    type="text"
                                                    className="form-control"
                                                    name="changeprofile_firstname"
                                                    id="changeprofile_firstname"
                                                    placeholder="*ชื่อจริง..."
                                                    defaultValue={firstname || ''}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                    ref={lastnameRef}
                                                    type="text"
                                                    className="form-control"
                                                    name="changeprofile_lastname"
                                                    id="changeprofile_lastname"
                                                    placeholder="*นามสกุล..."
                                                    defaultValue={lastname || ''}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                    ref={phoneNumberRef}
                                                    type="text"
                                                    className="form-control"
                                                    name="changeprofile_phoneNumber"
                                                    id="changeprofile_phoneNumber"
                                                    placeholder="*เบอร์โทร..."
                                                    defaultValue={phoneNumber || ''}
                                                    />
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" className="btn_1 full-width">
                                                        {
                                                            enableChangeProfile ? <>
                                                                <i className="ti-pencil"></i> แก้ไขข้อมูลส่วนตัว
                                                            </> : <>
                                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span class="sr-only"> กำลังทำรายการ...</span>
                                                            </>
                                                        }
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                                        <div className="form_container" style={{
                                            borderRadius: "15px"
                                        }}>
                                            <i className="ti-key"></i> เปลี่ยนรหัสผ่าน
                                            <hr/>
                                            <form onSubmit={changePasswordSubmit}>
                                                <div className="form-group">
                                                    <input
                                                        ref={old_passwordRef}
                                                        type="password"
                                                        className="form-control"
                                                        name="changepassword_oldPassword"
                                                        id="changepassword_oldPassword"
                                                        placeholder="*รหัสผ่านเดิม"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        ref={new_passwordRef}
                                                        type="password"
                                                        className="form-control"
                                                        name="changepassword_newPassword"
                                                        id="changepassword_newPassword"
                                                        placeholder="*รหัสผ่านใหม่"
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        ref={new_passwordConfirm}
                                                        type="password"
                                                        className="form-control"
                                                        name="changepassword_newPasswordConfirm"
                                                        id="changepassword_newPasswordConfirm"
                                                        placeholder="*ยืนยันรหัสผ่านใหม่"
                                                    />
                                                </div>
                                                <div className="text-center">
                                                    <button type="submit" className="btn_1 full-width" >
                                                        {
                                                            enableChangePassword ? <>
                                                                <i className="ti-check"></i> เปลี่ยนรหัสผ่าน
                                                            </> : <>
                                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span class="sr-only"> กำลังทำรายการ...</span>
                                                            </>
                                                        }
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <hr/>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                                        <div className="form_container" style={{
                                            borderRadius: "15px"
                                        }}>
                                            <div className="d-flex justify-content-between bd-highlight">
                                                <div className="p-2 bd-highlight"><i className="ti-home"></i> จัดการที่อยู่</div>
                                                <div className="p-2 bd-highlight">
                                                    <Link to="/account/new/address">
                                                        <i className="ti-plus"></i> เพิ่มที่อยู่
                                                    </Link>
                                                </div>
                                            </div>

                                            {/* <div className="text-start"><i className="ti-home"></i> จัดการที่อยู่</div>
                                            <div className="text-end">End aligned text on all viewport sizes.</div> */}
                                            <hr/>
                                            <div className="row">
                                                {
                                                    allAddress.length > 0 ? allAddress.map((address, index) => {
                                                        return (
                                                            <div className="col-sm-12 col-md-4 mb-3" key={index} >
                                                                <div className="card shadow-sm" style={
                                                                    {
                                                                        height:"160px"
                                                                    }
                                                                }>
                                                                    <div className="card-body">
                                                                        ผู้รับ: <b>{address.firstname} {address.lastname}</b> (<a href="javascript:void(0);" onClick={() => {
                                                                            // console.log(`Delete ${address.id}`)
                                                                            deleteAddress(address.id)
                                                                        }}>ลบ</a>)
                                                                        <hr/>
                                                                        {address.address} จังหวัด {address.province} รหัสไปรษณีย์ {address.zip_code}
                                                                        <hr/>
                                                                        เบอร์: {address.phone}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) : <div className="text-center">
                                                        คุณยังไม่ได้เพิ่มที่อยู่
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <Skeleton count={10} />
                }
            </div>
        </main>
    )
}

export default Account;