// import Js from '../../Js';
import './../../assets/css/listing.css'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
// import './../../assets/css/range_slider.css'
import './../../assets/css/ProductPage.css'
import { FaShoppingCart } from "react-icons/fa";

const ProductsNew = (components) => {
    const navigate = useNavigate();
    const perPage = 15;
    const [allProducts, setAllProducts] = useState(components.allProducts);
    const [wishlisted, setWishlisted] = useState(components.wishlist);
    const [categories, setCategories] = useState(components.categories);
    const [checkedCategories, setCheckedCategories] = useState(components.checkedCategories);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    const [defaultMinimumPrice, setDefaultMinimumPrice] = useState(null);
    const [defaultMaximumPrice, setDefaultMaximumPrice] = useState(null);

    const [minimumPrice, setMinimumPrice] = useState(null);
    const [maximumPrice, setMaximumPrice] = useState(null);

    useEffect(() => {
        setAllProducts(components.allProducts);
        setWishlisted(components.wishlist);
        setCategories(components.categories);
        setCheckedCategories(components.checkedCategories);
        setPageCount(Math.ceil(components.allProducts.length / perPage));

        if(defaultMinimumPrice == null || defaultMinimumPrice == 999999999999999)
        {
            if(allProducts.length > 0)
            {
                let setDefaultMinimumPrice_tmp = 999999999999999;
                let setDefaultMaximumPrice_tmp = 0;
                components.allProducts.map((product) => {
                    if(setDefaultMinimumPrice_tmp > parseFloat(product.price))
                    {
                        setDefaultMinimumPrice_tmp = parseFloat(product.price);
                    }

                    if(setDefaultMaximumPrice_tmp < parseFloat(product.price))
                    {
                        setDefaultMaximumPrice_tmp = (parseFloat(product.price)).toFixed(0);
                    }
                })

                setDefaultMinimumPrice(setDefaultMinimumPrice_tmp);
                setDefaultMaximumPrice(setDefaultMaximumPrice_tmp);
                setMinimumPrice(setDefaultMinimumPrice_tmp);
                setMaximumPrice(setDefaultMaximumPrice_tmp);
            }
        }
    }, [components]);

    // useEffect(() => {
        
    // }, [])

    return (
        <>
            <main>
                <div className="container margin_30">
                    <div className="row">
                    <aside className="col-lg-3" id="sidebar_fixed">
                        <div className="card mb-3 shadow-sm">
                            <div className="card-body">
                            <div className="inner_bt">
                                <a href="#" className="open_filters">
                                    {/* <i className="ti-close" /> */}
                                </a>
                            </div>
                            <div className="filter_type version_2">
                                <h4>
                                    <a data-bs-toggle="collapse" className="opened">
                                        หมวดหมู่
                                    </a>
                                </h4>
                                <div className="collapse show" id="filter_1">
                                <ul>
                                    {
                                        categories.length > 0 ? categories.map((category, index) => {
                                            if(category.count <= 0)
                                            {
                                                return;
                                            }
                                            return (
                                                <li key={index}>
                                                    <label className="container_check">
                                                        {category.name} <small>{parseFloat(category.count).toLocaleString("en-US")}</small>
                                                        <input type="checkbox" onClick={(e) => {
                                                            components.updateCheckedCategories(category.id)
                                                        }}/>
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            )
                                        }) : <></>
                                    }
                                    
                                </ul>
                                </div>
                            </div>
                            <div className="filter_type version_2">
                                <h4>
                                    <a data-bs-toggle="collapse" className="opened">
                                        ราคา {parseFloat(minimumPrice).toLocaleString("en-US")} - {parseFloat(maximumPrice).toLocaleString("en-US")}฿
                                    </a>
                                </h4>
                                
                                <div className="collapse show pb-4" id="filter_4">
                                    {
                                        defaultMinimumPrice == null || defaultMaximumPrice == null ? "Loading" : <RangeSlider
                                            defaultValue={[defaultMinimumPrice, defaultMaximumPrice]}
                                            min={defaultMinimumPrice}
                                            max={defaultMaximumPrice}
                                            onInput={(data) => {
                                                setMinimumPrice(data[0])
                                                setMaximumPrice(data[1])
                                            }}
                                        />
                                    }
                                    {/* <ul>
                                        <li>
                                        <label className="container_check">
                                            $0 — $50<small>11</small>
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        </li>
                                        <li>
                                        <label className="container_check">
                                            $50 — $100<small>08</small>
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        </li>
                                        <li>
                                        <label className="container_check">
                                            $100 — $150<small>05</small>
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        </li>
                                        <li>
                                        <label className="container_check">
                                            $150 — $200<small>18</small>
                                            <input type="checkbox" />
                                            <span className="checkmark" />
                                        </label>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                            </div>
                        </div>
                    </aside>
                    {/* /col */}
                    <div className="col-lg-9">
                        <div className="row small-gutters">
                                {
                                    allProducts.length > 0 ? allProducts.slice(itemOffset, (itemOffset + perPage)).filter(
                                        (product) => parseInt(parseFloat(maximumPrice).toFixed(0)) >= parseInt(parseFloat(product.price).toFixed(0)) && parseInt(parseFloat(minimumPrice).toFixed(0)) <= parseInt(parseFloat(product.price).toFixed(0))
                                    ).map((product, index) => {
                                        const hasWishlist = wishlisted.some((wishlist) => { 
                                            return wishlist.product_id == product.id
                                        });
                                        
                                        return (
                                            <div class="col-md-3 col-sm-12 mb-3">
                                                <div className="Product">   

                                                    {/* รูป */}
                                                    <img src={product.path != null ? product.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`} alt={product.name.slice(0, 20)} />

                                                    {/* {ชื่อสินค้า} */}
                                                    <p>
                                                        <Link to={`/products/${product.id}`}>
                                                            {product.name}
                                                        </Link>
                                                    </p>

                                                    {/* รายละเอียดสินค้า */}
                                                    {/* <p>{product.description}</p> */}

                                                    {/* ราคาสินค้า */}
                                                    <p>
                                                        ราคาเริ่มต้น: {
                                                            product.price != null ? parseFloat(product.price).toLocaleString("en-US") : parseFloat(0.00).toLocaleString("en-US")
                                                        }฿
                                                    </p>

                                                    {/* ราคาที่ลดแล้ว */}
                                                    {/* <p>สมาชิก $ {(item.price - (item.price * Discount / 100)).toFixed(2)} <span>-{Discount}%</span>  </p> */}


                                                        <button onClick={() => {
                                                            navigate(`/products/${product.id}`);
                                                        }}><FaShoppingCart/> สั่งซื้อสินค้า</button>
                                                    
                                                    {/* <p><small>(มีหลายคุณสมบัติให้เลือก)</small></p> */}
                                                </div>
                                            </div>
                                        )
                                    }) : <div className="col-6 col-md-4">ไม่พบสินค้าที่ค้นหา</div>
                                }
                        </div>
                        {/* /row */}
                        <div className="pagination__wrapper" key={`productpagination`}>
                            <ul className="pagination">
                                <li>
                                    <a className="prev" title="Previous page" onClick={() => {
                                        if(page > 1)
                                        {
                                            setItemOffset(((page + 1) * perPage) % allProducts.length);
                                            setPage(page - 1);
                                        }
                                    }}>
                                        ❮
                                    </a>
                                </li>
                                    {
                                        allProducts.length > 0 ? [...Array(pageCount)].map((x,i) => {
                                            return (
                                                <li key={i}>
                                                    <a className={
                                                        page == (i + 1) ? "active" : ""
                                                    } onClick={() => {
                                                        setPage(i + 1);
                                                        setItemOffset(((i) * perPage) % allProducts.length);
                                                    }}>
                                                        {i + 1}
                                                    </a>
                                                </li>
                                            )
                                        }) : <li key={0}>
                                                <a href="#0" className="active">
                                                    1
                                                </a>
                                            </li>
                                    }
                                <li>
                                    <a className="next" title="Next page" onClick={() => {
                                        if(page < pageCount)
                                        {
                                            setItemOffset(((page) * perPage) % allProducts.length);
                                            setPage(page + 1);
                                        }
                                    }}>
                                        ❯
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* /col */}
                    </div>
                    {/* /row */}
                </div>
                {/* /container */}
                </main>
        </>
    )
}

export default ProductsNew;