import './../../assets/css/Top_Navbar.css';
import { FaFacebook } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaLine } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import Thailand from './../../assets/img/thailand.png';
import United_Kingdom from './../../assets/img/united-kingdom.png';
import { FaChevronDown } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const TopHeader = (components) => {
    const [select, setSelect] = useState(true);
    const [openOption, setOpenOption] = useState(true);
    const [webInformation, setWebInformation] = useState({});

    useEffect(() => {
        setWebInformation(components.webInformation)
    }, [components.webInformation])

    const OnClickSelect = () => {
        setOpenOption(!openOption)
    }
    const OnClickSelectedThailand = () => {
        setSelect(true)
        setOpenOption(true)
    }
    const OnClickSelectedEnglish = () => {
        setSelect(false)
        setOpenOption(true)
    }

    return (
        <div className='Container_Top_Navbar'>
            <div className="Social" style={
                {
                    paddingTop: "3px"
                }
            }>
                {
                    webInformation.facebook != null && <Link to={webInformation.facebook} target="_blank">
                        <FaFacebook size={20}/>
                    </Link>
                }
                {
                    webInformation.youtube != null && <Link to={webInformation.youtube} target="_blank">
                        <IoLogoYoutube size={20} color='red'/>
                    </Link>
                }
                {
                    webInformation.line != null && <Link to={`https://line.me/ti/p/~${webInformation.line}`} target="_blank">
                        <FaLine size={20} color='green'/>
                    </Link>
                }
            </div>
            <div className="Call_Now">
                    
                <FaPhoneVolume color='rgb(34, 40, 124)' size={30}/>  CALLNOW : {
                    webInformation.phone_number != undefined ? webInformation.phone_number.split(",").map((number, idx) => {
                        const show_number = webInformation.phone_number.split(",").length == (idx + 1) ? `${number}` : `${number},`
                        return (
                            <>
                                <Link to={`tel:${number}`}>{show_number}</Link>
                            </>
                        )
                    }) : ""
                }
            </div>
            <div className="Change_Language" style={{ zIndex: '100000'}}>
                <div onClick={OnClickSelect}>
                    <img src={select ? Thailand : United_Kingdom} alt={select ? Thailand : United_Kingdom} /> <FaChevronDown size={12} />
                </div>
                {
                    openOption ? '' : (
                        <div class="Dropdown_Content" style={{ backgroundColor: 'white'}}>
                            <p onClick={OnClickSelectedThailand}><img src={Thailand} alt={Thailand} /> ไทย  <span>{select ? <FaCheck size={8}/> : '' }</span></p>
                            <p onClick={OnClickSelectedEnglish}><img src={United_Kingdom} alt={United_Kingdom} /> English  <span>{!select ? <FaCheck size={8}/> : '' }</span> </p>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default TopHeader;