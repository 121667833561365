
import Cookies from 'universal-cookie';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Js from './Js';
import toast, { Toaster } from 'react-hot-toast';

import 'react-loading-skeleton/dist/skeleton.css'

import Home from './Home';
import Header from './components/header/Header';
import NavbarMobile from './components/header/NavbarMobile';
import LoginOrRegister from './components/member/loginorregister';
import Logout from './components/member/Logout';
// import Products from './components/products/Products';
import ProductsNew from './components/products/ProductsNew';
import Product from './components/products/Product';
import Carts from './components/carts/Carts';
import Goto from './goto';
import Blogs from './components/blogs/Blogs';
import Blog from './components/blogs/Blog';
import NewAddress from './components/member/NewAddress';
import AdminHome from './components/Admin/Admin';
import NewFooter from './components/footer/NewFooter';
import TopHeader from './components/header/TopHeader';
import GotoBlog from './GotoBlog';
import WishList from './components/wishlist/Wishlist';
import ProductWithAff from './components/products/ProductWithAffiliate';
import Social_Menu from './Social_Menu';

// CSS
import './assets/css/bootstrap.min.css'
import './assets/css/style.css'
import './assets/css/home_1.css'
import './assets/css/custom.css'
import './assets/css/modalImage.css'

function App() {
  const cookies = new Cookies(null, { path: '/' });
  const [statusToken, setStatusToken] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [carts, setCarts] = useState([]);
  const [totalsInCarts, setTotalsInCarts] = useState(0);
  const [wishlist, setWishlist] = useState([]);
  const [categories, setCategories] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [webInformation, setWebInformation] = useState({});
  const [banner, setBanner] = useState([]);

  const notify = (msg, options) => toast(msg, {...options});

  const notify_error = (msg) => {
      notify(msg, {
          duration: 5000,
          style: {
              color: "white",
              backgroundColor: "red"
          },
          icon: '❌',
          position: 'bottom-center',
          iconTheme: {
              primary: '#000',
              secondary: '#fff',
          },
      });
  }

  const notify_success = (msg) => {
      notify(msg, {
          duration: 3000,
          style: {
              color: "white",
              backgroundColor: "#004dda"
          },
          icon: '✔️',
          position: 'bottom-center',
          iconTheme: {
              primary: '#000',
              secondary: '#fff',
          },
      });
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
      headers: {
        token: cookies.get('token') || null
      }
    }).then((response) => {
      if(response.data.message === 'Token is vailded')
      {
        setStatusToken(true);
        reloadCarts();
        reloadWishlist();
        response.data.isAdmin == 1 ? setIsAdmin(true) : setIsAdmin(false);
      }
      else
      {
        setStatusToken(false);
        cookies.remove('token');
      }

      reloadProducts();
      reloadCategories();
      reloadBlogs();
    });

    reloadWebInformation();
    reloadBanner();
  }, []);

  const reloadStatusAdmin = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
      headers: {
        token: cookies.get('token') || null
      }
    }).then((response) => {
      if(response.data.message === 'Token is vailded')
      {
        setStatusToken(true);
        reloadCarts();
        reloadWishlist();
        response.data.isAdmin == 1 ? setIsAdmin(true) : setIsAdmin(false);
      }
      else
      {
        setStatusToken(false);
        cookies.remove('token');
      }
    });
  }

  const reloadWebInformation = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/information`).then((response) => {
      if(response.data.message == 'OK')
      {
        setWebInformation(response.data.information);
      }
    });
  }
  
  const reloadBanner = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/banner`).then((response) => {
      if(response.data.message == 'OK')
      {
        setBanner(response.data.banner);
      }
    });
  }

  const reloadProducts = (array) => {
    if(array === undefined)
    {
      axios.get(`${process.env.REACT_APP_API_URL}/products/all`).then((response) => {
        setAllProducts(response.data);
      })
    }
    else if(array.length == 0)
    {
      axios.get(`${process.env.REACT_APP_API_URL}/products/all`).then((response) => {
        setAllProducts(response.data);
      })
    }
    else
    {
      axios.post(`${process.env.REACT_APP_API_URL}/products/inCategory`, {
        array: array
      }).then((response) => {
        setAllProducts(response.data);
      })
    }
  }

  const reloadBlogs = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/blogs`).then((response) => {
      if(response.data.message == 'success')
      {
        setAllBlogs(response.data.blogs);
      }
    })
  }

  const reloadCarts = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/carts/get`, {
        headers: {
            token: cookies.get('token')
        }
    }).then((response) => {
        const sortCartsByProductCreated = response.data.carts.sort(function(a, b){
          var d1 = new Date(a.productCreatedAt);
          var d2 = new Date(b.productCreatedAt);
          return d1-d2;
        });
        const sortCartsByPriceCreated = sortCartsByProductCreated.sort(function(a, b){
          var d1 = new Date(a.priceCreatedAt);
          var d2 = new Date(b.priceCreatedAt);
          return d1-d2;
        })

        setCarts(sortCartsByPriceCreated);
        setTotalsInCarts(response.data.totals);
    })
  }

  const reloadCategories = (a) => {
    axios.get(`${process.env.REACT_APP_API_URL}/categories/all`).then((response) => {
      setCategories(response.data);
    })
  }

  const removeProductInCart = (product_id, price_id) => {
    axios.post(`${process.env.REACT_APP_API_URL}/carts/delete`, {
      product_id: product_id,
      price_id: price_id
    },{
      headers: {
        token: cookies.get('token')
      }
    }).then((response) => {
      const sortCartsByProductCreated = response.data.carts.sort(function(a, b){
        var d1 = new Date(a.productCreatedAt);
        var d2 = new Date(b.productCreatedAt);
        return d1-d2;
      });
      const sortCartsByPriceCreated = sortCartsByProductCreated.sort(function(a, b){
        var d1 = new Date(a.priceCreatedAt);
        var d2 = new Date(b.priceCreatedAt);
        return d1-d2;
      })

      setCarts(sortCartsByPriceCreated);
      setTotalsInCarts(response.data.totals);
    })
  }

  const reloadWishlist = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/products/wishlist/all`, {
      headers: {
        token: cookies.get('token')
      }
    }).then((response) => {
      if(response.data.message == 'OK')
      {
        setWishlist(response.data.wishlist)
      }
    })
  }

  const addToWishlist = (product_id) => {
    axios.post(`${process.env.REACT_APP_API_URL}/products/wishlist/add`, {
      product_id: product_id
    }, {
      headers: {
        token: cookies.get('token')
      }
    }).then((response) => {
      if(response.data.message == 'Success')
      {
        reloadWishlist();
        notify_success('เพิ่มรายการ Wishlist เรียบร้อยแล้ว');
      }
      else
      {
        notify_error('เพิ่มรายการ Wishlist ไม่สำเร็จ');
      }
    })
  }

  const removeWishlist = (product_id) => {
    axios.post(`${process.env.REACT_APP_API_URL}/products/wishlist/remove`, {
      product_id: product_id
    }, {
      headers: {
        token: cookies.get('token')
      }
    }).then((response) => {
      if(response.data.message == 'Success')
      {
        reloadWishlist();
        notify_success('ลบรายการ Wishlist เรียบร้อยแล้ว');
      }
      else
      {
        notify_error('ลบรายการ Wishlist ไม่สำเร็จ');
      }
    })
  }

  const updateCheckedCategories = (category_id) => {
    let newSetChecked = [];
    if(checkedCategories.length == 0)
    {
        newSetChecked.push(category_id)
    }
    else
    {
        const findIndex = checkedCategories.find((element) => {
            return element == category_id
        })

        if(findIndex == undefined)
        {
            newSetChecked = checkedCategories;
            newSetChecked.push(category_id)
        }
        else
        {
            checkedCategories.map((id) => {
                if(id != category_id)
                {
                    newSetChecked.push(id)
                }
            })
        }
    }

    setCheckedCategories(newSetChecked)
    reloadProducts(newSetChecked);
  }

  return (
    <div className='App'>
        <Helmet>
          <title>
            {
              webInformation.title == undefined ? "กรุงเทพเคมี" : webInformation.title
            }
          </title>
          <meta name="description" content={
              webInformation.description == undefined ? "กรุงเทพเคมี" : webInformation.description
            } />
          <meta name="keywords" content="กรุงเทพเคมี, เคมี, เคมีภันฑ์" />
          <meta name="author" content="กรุงเทพเคมี" />
          <meta property="og:title" content={
              webInformation.title == undefined ? "กรุงเทพเคมี" : webInformation.title
            } />
          <meta property="og:description" content={
              webInformation.description == undefined ? "กรุงเทพเคมี" : webInformation.description
            } />
          <meta property="og:image" content={`${process.env.REACT_APP_BASE_URL}/img/logo_none_text_original.png`} />
          <meta property="og:url" content={`${process.env.REACT_APP_BASE_URL}/`} />
        </Helmet>
        <Toaster />
        <Router>
          <TopHeader webInformation={webInformation} />
          <NavbarMobile />
          <Header carts={carts} totals={totalsInCarts} removeProductInCart={removeProductInCart} allProducts={allProducts} statusToken={statusToken} isAdmin={isAdmin} />
            <Routes>
              <Route path="/" exact element={
                <Home
                  allProducts={allProducts}
                  allBlogs={allBlogs}
                  allCategory={categories}
                  banner={banner}
                />
              } />
              <Route path="/blogs" exact element={
                <Blogs
                  allBlogs={allBlogs}
                />
              } />
              <Route path="/blog/:blog_id" exact element={
                <Blog
                  allBlogs={allBlogs}
                />
              } />
              <Route path="/goto/:product_id" exact element={<Goto />} />
              <Route path="/gotoblog/:blog_id" exact element={<GotoBlog />} />
              <Route path="/account" exact element={<LoginOrRegister statusToken={statusToken} setStatusToken={setStatusToken} reloadCarts={reloadCarts} reloadWishlist={reloadWishlist} reloadStatusAdmin={reloadStatusAdmin} />} />
              <Route path="/account/new/address" exact element={ <NewAddress /> } />
              <Route path="/logout" exact element={<Logout setStatusToken={setStatusToken} />} />
              <Route path="/products" exact element={
                <ProductsNew
                  allProducts={allProducts}
                  wishlist={wishlist}
                  addToWishlist={addToWishlist}
                  removeWishlist={removeWishlist}
                  categories={categories}
                  setCheckedCategories={setCheckedCategories}
                  checkedCategories={checkedCategories}
                  updateCheckedCategories={updateCheckedCategories}
                />}
              />
              <Route path="/products/:product_id" exact element={<Product reloadCarts={reloadCarts} wishlist={wishlist} addToWishlist={addToWishlist} removeWishlist={removeWishlist} />} />
              <Route path="/products/:product_id/:affiliate" exact element={<ProductWithAff reloadCarts={reloadCarts} wishlist={wishlist} addToWishlist={addToWishlist} removeWishlist={removeWishlist} />} />
              <Route path="/wishlist" exact element={<WishList wishlists={wishlist} allProducts={allProducts} removeWishlist={removeWishlist} />} />
              <Route path="/carts" exact element={<Carts carts={carts} totals={totalsInCarts} setCarts={setCarts} setTotalsInCarts={setTotalsInCarts} removeProductInCart={removeProductInCart} />} />
              <Route path="/admin" exact element={
                <AdminHome
                  allBlogs={allBlogs}
                  allProducts={allProducts}
                  wishlist={wishlist}
                  addToWishlist={addToWishlist}
                  removeWishlist={removeWishlist}
                  categories={categories}
                  setCheckedCategories={setCheckedCategories}
                  checkedCategories={checkedCategories}
                  updateCheckedCategories={updateCheckedCategories}
                  reloadProducts={reloadProducts}
                  reloadCategories={reloadCategories}
                  notify_success={notify_success}
                  notify_error={notify_error}
                  banner={banner}
                  reloadBanner={reloadBanner}
                  webInformation={webInformation}
                  reloadWebInformation={reloadWebInformation}
                  reloadBlogs={reloadBlogs}
                  isAdmin={isAdmin}
                  statusToken={statusToken}
                  setStatusToken={setStatusToken}
                  reloadCarts={reloadCarts}
                  reloadWishlist={reloadWishlist}
                  reloadStatusAdmin={reloadStatusAdmin}
                />
              } />
            </Routes>
          {/* <Footer categories={categories} /> */}
          <NewFooter categories={categories} webInformation={webInformation} />
          <div id="toTop"></div>
          <Social_Menu webInformation={webInformation} />
          <Js scriptURL={`${process.env.REACT_APP_BASE_URL}/js/common_scripts.min.js`} />
          <Js scriptURL={`${process.env.REACT_APP_BASE_URL}/js/main.js`} />
        </Router>
    </div>
  );
  
}

export default App;