import { useEffect, useState } from "react";
import NewProduct from "./components/products/NewProduct";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Carousel from "./components/carousel/Carousel";
import HomeBlogs from "./components/blogs/HomeBlogs";
import ProductCategory from "./components/products/ProductCategory";

// import LastNew from "./components/LastNew";

const Home = (components) => {
  const [allProducts, setAllProducts] = useState(components.allProducts);
  const [allBlogs, setAllBlogs] = useState(components.allBlogs);
  const [allCategories, setAllCategories] = useState(components.allCategory);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    setAllProducts(components.allProducts);
    setAllBlogs(components.allBlogs);
    setAllCategories(components.allCategory);
    setFilteredProducts(components.allProducts.filter((product) => product.category_id === 1));
    setBanner(components.banner);
  }, [components])

  const onSelectedCategory = (category_id) => {
    setFilteredProducts(components.allProducts.filter((product) => product.category_id === category_id));
  }

  return (
    <main id="Main" className="main">
      {
        allProducts.length > 0 ? <>
          <Carousel banner={banner} />
          <ProductCategory
            allProducts={filteredProducts}
            allCategories={allCategories}
            selectCategory={onSelectedCategory}
          />
          <NewProduct
            allProducts={allProducts}
          />
          <HomeBlogs allBlogs={allBlogs} />
          {/* <LastNew /> */}

        </> : <div className="container py-4">
          <Skeleton count={10} />
        </div>
      }
    </main>
  );
}

export default Home;