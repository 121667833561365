'use client'
import axios from 'axios';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import toast, { Toaster } from 'react-hot-toast';
const cookies = new Cookies(null, { path: '/' });

function Login(components) {
    const notify = (msg, options) => toast(msg, {...options});
    const navigate = useNavigate();

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const LoginSubmit = async (event) => {
        event.preventDefault();

        const sendData = {
            email: emailRef.current.value,
            password: passwordRef.current.value
        }

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/members/member/login/`, sendData);
        if(response.data.message === 'Logged in')
        {
            cookies.set('token', response.data.token);
            components.setStatusToken(true);
            components.reloadCarts();
            components.reloadWishlist();
            components.reloadStatusAdmin();
            return navigate('/');
        }

        notify(response.data.message, {
            duration: 5000,
            style: {
                color: "white",
                backgroundColor: "red"
            },
            icon: '❌',
            position: 'bottom-center',
            iconTheme: {
                primary: '#000',
                secondary: '#fff',
            },
        });
    }

    return (
        <form onSubmit={LoginSubmit}>
            <div className="form-group">
                <input
                    ref={emailRef}
                    type="text"
                    className="form-control"
                    name="login_email"
                    id="login_email"
                    placeholder="Email*"
                />
            </div>
            <div className="form-group">
                <input
                    ref={passwordRef}
                    type="password"
                    className="form-control"
                    name="login_password"
                    id="login_password"
                    defaultValue=""
                    placeholder="Password*"
                />
            </div>
            <div className="clearfix add_bottom_15">
                <div className="float-end">
                <a id="forgot" href="javascript:void(0);">
                    ลืมรหัสผ่าน ?
                </a>
                </div>
            </div>
            <div className="text-center">
                <button
                    type="submit"
                    className="btn_1 full-width"
                >
                    เข้าสู่ระบบ
                </button>
            </div>
            {/* <div id="forgot_pw">
                <div className="form-group">
                <input
                    type="email"
                    className="form-control"
                    name="email_forgot"
                    id="email_forgot"
                    placeholder="Type your email"
                />
                </div>
                <p>A new password will be sent shortly.</p>
                <div className="text-center">
                <input
                    type="submit"
                    defaultValue="Reset Password"
                    className="btn_1"
                />
                </div>
            </div> */}
        </form>
    );
}

export default Login;