import { useState, useRef } from "react";
import axios from "axios";
import SimpleMDE from "react-simplemde-editor";
import Cookies from "universal-cookie";

const NewBlog = (components) => {
    const cookies = new Cookies(null, { path: '/' });
    
    const [blog, setBlog] = useState({});
    const [blogImage, setBlogImage] = useState("");
    const [blogHTML, setBlogHTML] = useState("");
    const titleRef = useRef(null);
    const fileRef = useRef(null);

    const onChangeBlogHTML = (value) => {
        setBlogHTML(value);
    };

    const submitCreateBlog = (event) => {
        event.preventDefault();
        const title = titleRef.current.value;
        const html = blogHTML;
        const image_path = blogImage;

        if(title == undefined || title == '')
        {
            components.notify_error('หัวเรื่อง ไม่สามารถเว้นว่างได้');
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/blogs/create`, {
            title: title,
            html: html,
            image_path: image_path
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            console.log(response)
            if(response.data.message == 'OK')
            {
                components.notify_success('เพิ่ม Blog เรียบร้อยแล้ว');
                components.reloadBlogs();
                components.setPage('allblog');
            }
            else
            {
                components.notify_error(`เพิ่ม Blog ไม่สำเร็จ (${response.data.message})`);
            }
        });
    }

    const uploadImageBlog = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        
        axios.post(`${process.env.REACT_APP_API_URL}/upload/blogImg`, formData, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                const image_path = response.data.path;
                setBlogImage(image_path);
                components.notify_success('อัพโหลดรูปภาพเรียบร้อยแล้ว');
            }
            else
            {
                components.notify_error(`อัพโหลดรูปภาพไม่สำเร็จ (${response.data.message})`)
            }
        })
    }

    return (
        <form onSubmit={submitCreateBlog}>
            <div className="row mt-3">
                <div className="col-12">
                    <img src={blogImage != "" ? blogImage.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/blog-thumb-1.jpg`} class="rounded mx-auto d-block" style={
                        {
                            maxWidth: "200px",
                            maxHeight: "133px"
                        }
                    } />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-3">
                    <input name="file_img" id="file_img" ref={fileRef} type="file" className="form-control"/>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-3">
                    <button type="button" className="btn_1 full-width" onClick={() => {
                        uploadImageBlog(fileRef.current.files[0]);
                    }}>
                        อัพเดทรูปภาพ
                    </button>
                </div>
                <div className="col-12 my-3">
                    <label for="blog_title">หัวเรื่อง:</label>
                    <input name="blog_title" id="blog_title" type="text" className="form-control" placeholder="หัวเรื่อง" ref={titleRef}/>
                </div>
                <div className="col-12 mb-3">
                    <label>BLOG:</label>
                    <SimpleMDE
                        value={blogHTML}
                        onChange={onChangeBlogHTML}
                    />
                </div>
                <div className="col-12">
                    <button type="submit" className="btn_1 full-width">
                        <i className="ti-pencil"></i> เพิ่ม Blog
                    </button>
                </div>
            </div>
        </form>
    )
}

export default NewBlog;