import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { marked } from "marked";

import './../../assets/css/blog.css';

const Blogs = (components) => {
    const navigate = useNavigate();
    const perPage = 6;
    const [allBlogs, setAllBlogs] = useState(components.allBlogs);

    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        setAllBlogs(components.allBlogs);
        setPageCount(Math.ceil(components.allBlogs.length / perPage));
    }, [components]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const removeTagHTML = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/(<([^>]+)>)/ig, '');
    }

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        // console.log(string, results)
      }
    
      const handleOnHover = (result) => {
        // the item hovered
        // console.log(result)
      }
    
      const handleOnSelect = (item) => {
        // the item selected
        // console.log(item)
        navigate(`/gotoblog/${item.id}`);
        // navigate(`/products/${item.id}`);
      }
    
      const handleOnFocus = () => {
        // console.log('Focused')
      }
    
      const formatResult = (item) => {
        return (
          <>
            <a href={`/gotoblog/${item.id}`} style={{ display: 'block', textAlign: 'left' }}>{item.title} <small>เขียนเมื่อ: {item.createdAt}</small></a>
          </>
        )
      }

    return (
        <main className="bg_gray">
            <div className="container margin_30">
                <div className="page_header">
                <div className="breadcrumbs">
                    <ul>
                    <li>
                        <Link to="/">หน้าหลัก</Link>
                    </li>
                    <li>บทความ</li>
                    </ul>
                </div>
                <h1>บทความทั้งหมด</h1>
                </div>
                {/* /page_header */}
                <div className="row">
                    <div className="col-lg-9">
                        <div className="widget search_blog d-block d-sm-block d-md-block d-lg-none">
                            <div className="form-group">
                                <input
                                type="text"
                                name="search"
                                id="search"
                                className="form-control"
                                placeholder="Search.."
                                />
                                <button type="submit">
                                <i className="ti-search" />
                                </button>
                            </div>
                        </div>
                        {/* /widget */}
                        <div className="row">
                            {
                                allBlogs.length > 0 ? allBlogs.slice(itemOffset, (itemOffset + perPage)).map((blog, index) => {
                                    return (
                                        <div className="col-md-6" key={index}>
                                            <article className="blog" style={{height: "450px", maxHeight: "450px"}}>
                                                <figure>
                                                    <Link to={`/gotoblog/${blog.id}`}>
                                                        <img src={blog.path != "" ? blog.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/blog-thumb-1.jpg`} alt="" />
                                                        <div className="preview">
                                                            <span>อ่านเพิ่มเติม</span>
                                                        </div>
                                                    </Link>
                                                </figure>
                                                <div className="post_info">
                                                    <small>เขียนเมื่อ {blog.createdAt}</small>
                                                    <h2>
                                                        <Link to={`/gotoblog/${blog.id}`}>
                                                            {blog.title}
                                                        </Link>
                                                    </h2>
                                                    <p>
                                                        {
                                                            removeTagHTML(marked.parse(blog.html)).length > 200 ? removeTagHTML(marked.parse(blog.html)).slice(0, 200) + "..." : removeTagHTML(marked.parse(blog.html))
                                                        }
                                                    </p>
                                                    <p>
                                                        <Link to={`/gotoblog/${blog.id}`}>
                                                            อ่านเพิ่มเติม
                                                        </Link>
                                                    </p>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                }) : <Skeleton count={50} />
                            }
                            
                        </div>
                        <div className="pagination__wrapper no_border add_bottom_30" key={`blogpagination`}>
                            <ul className="pagination">
                                <li>
                                    <a className="prev" title="Previous page" onClick={() => {
                                        if(page > 1)
                                        {
                                            setItemOffset(((page + 1) * perPage) % allBlogs.length);
                                            setPage(page - 1);
                                        }
                                    }}>
                                        ❮
                                    </a>
                                </li>
                                    {
                                        allBlogs.length > 0 ? [...Array(pageCount)].map((x,i) => {
                                            return (
                                                <li key={i}>
                                                    <a className={
                                                        page == (i + 1) ? "active" : ""
                                                    } onClick={() => {
                                                        setPage(i + 1);
                                                        setItemOffset(((i) * perPage) % allBlogs.length);
                                                    }}>
                                                        {i + 1}
                                                    </a>
                                                </li>
                                            )
                                        }) : <li key={0} >
                                                <a href="#0" className="active">
                                                    1
                                                </a>
                                            </li>
                                    }
                                <li>
                                    <a className="next" title="Next page" onClick={() => {
                                        if(page < pageCount)
                                        {
                                            setItemOffset(((page) * perPage) % allBlogs.length);
                                            setPage(page + 1);
                                        }
                                    }}>
                                        ❯
                                    </a>
                                </li>
                            </ul>
                        </div>
                </div>
                {/* /col */}
                <aside className="col-lg-3">
                    <div className="widget search_blog d-none d-sm-none d-md-none d-lg-block">
                        <ReactSearchAutocomplete
                            items={allBlogs}
                            onSearch={handleOnSearch}
                            onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            onFocus={handleOnFocus}
                            fuseOptions={{ keys: ["title"] }}
                            // resultStringKeyName="code"
                            // autoFocus
                            styling={{"width":"100%","height":"40px","backgroundColor":"#fff","padding":"15px","zIndex":1}}
                            formatResult={formatResult}
                            placeholder="ค้นหาบทความได้ที่นี่..."
                            maxResults={3}
                            showNoResultsText="ไม่พบบทความที่คุณค้นหา"
                        />
                    </div>
                    {/* /widget */}
                    <div className="widget">
                    <div className="widget-title">
                        <h4>บทความล่าสุด</h4>
                    </div>
                    <ul className="comments-list">
                        {
                            allBlogs.length > 0 ? allBlogs.map((blog, index) => {
                                return (
                                    <li key={index}>
                                        <div className="alignleft">
                                            <Link href="#0">
                                                <img src={blog.path != "" ? blog.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/blog-thumb-1.jpg`} alt="" />
                                            </Link>
                                        </div>
                                        <small>เขียนเมื่อ {blog.createdAt}</small>
                                        <h3>
                                            <Link to={`/gotoblog/${blog.id}`}>
                                                {blog.title}
                                            </Link>
                                        </h3>
                                    </li>
                                )
                            }) : <Skeleton count={10} />
                        }
                    </ul>
                    </div>
                    {/* /widget */}
                    {/* <div className="widget">
                    <div className="widget-title">
                        <h4>Categories</h4>
                    </div>
                    <ul className="cats">
                        <li>
                        <a href="#">
                            Food <span>(12)</span>
                        </a>
                        </li>
                        <li>
                        <a href="#">
                            Places to visit <span>(21)</span>
                        </a>
                        </li>
                        <li>
                        <a href="#">
                            New Places <span>(44)</span>
                        </a>
                        </li>
                        <li>
                        <a href="#">
                            Suggestions and guides <span>(31)</span>
                        </a>
                        </li>
                    </ul>
                    </div>
                    {/* /widget */}
                    {/* <div className="widget">
                    <div className="widget-title">
                        <h4>Popular Tags</h4>
                    </div>
                    <div className="tags">
                        <a href="#">Food</a>
                        <a href="#">Bars</a>
                        <a href="#">Cooktails</a>
                        <a href="#">Shops</a>
                        <a href="#">Best Offers</a>
                        <a href="#">Transports</a>
                        <a href="#">Restaurants</a>
                    </div>
                    </div> */}
                    {/* /widget */}
                </aside>
                {/* /aside */}
                </div>
                {/* /row */}
            </div>
            {/* /container */}
        </main>

    )
}

export default Blogs;