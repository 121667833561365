import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";

import AllProducts from "./pages/AllProducts";
import Product from "./pages/Product";
import AllCategories from "./pages/AllCategories";
import Banner from "./pages/Banner";
import WebInfomation from "./pages/WebInfomation";
import AllBlogs from "./pages/AllBlogs";
import Blog from "./pages/Blog";
import NewBlog from "./pages/NewBlog";
import Login from "./Login";

const AdminHome = (components) => {
    const cookies = new Cookies(null, { path: '/' });
    const [page, setPage] = useState('dashboard');
    const [productId, setProductId] = useState(0);
    const [newProductId, setNewProductId] = useState(0);
    const [blogId, setBlogId] = useState(0);

    return components.isAdmin ? (
        <main>
            <div className="container margin_30">
                <div className="row">
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row justify-content-center">
                                <div className="col-md-2 col-sm-12">
                                    <div className="card">
                                        <div className="card-header bg-primary text-white text-center">
                                            <i className="ti-list"></i> เมนู
                                        </div>
                                        <ul className="list-group list-group-flush">
                                            <Link className={`list-group-item ${page == 'dashboard' && 'active text-white'}`} onClick={() => {
                                                    setPage('dashboard')
                                                }}>
                                                Dashboard
                                            </Link>
                                            <Link className={`list-group-item ${page == 'all_products' && 'active text-white'}`} onClick={() => {
                                                    setPage('all_products')
                                                }}>
                                                สินค้าทั้งหมด
                                            </Link>
                                            <Link className={`list-group-item ${page == 'new_prodcut' && 'active text-white'}`} onClick={async () => {
                                                    await axios.post(`${process.env.REACT_APP_API_URL}/products/newproduct`, {}, {
                                                        headers: {
                                                            token: cookies.get('token')
                                                        }
                                                    }).then((response) => {
                                                        if(response.data.message == 'OK')
                                                        {
                                                            setProductId(response.data.id)
                                                            setPage('new_prodcut');
                                                            components.reloadProducts();
                                                        }
                                                    });
                                                }}>
                                                เพิ่มสินค้าใหม่
                                            </Link>
                                            <Link className={`list-group-item ${page == 'all_categories' && 'active text-white'}`} onClick={() => {
                                                    setPage('all_categories')
                                                }}>
                                                หมวดหมู่ทั้งหมด
                                            </Link>
                                            <Link className={`list-group-item ${page == 'allblog' && 'active text-white'}`} onClick={() => {
                                                    setPage('allblog')
                                                }}>
                                                Blog ทั้งหมด
                                            </Link>
                                            <Link className={`list-group-item ${page == 'newblog' && 'active text-white'}`} onClick={() => {
                                                    setPage('newblog')
                                                }}>
                                                เขียน Blog ใหม่
                                            </Link>
                                            <Link className={`list-group-item ${page == 'banner' && 'active text-white'}`} onClick={() => {
                                                    setPage('banner')
                                                }}>
                                                ตั้งค่ารูปภาพ Slide
                                            </Link>
                                            <Link className={`list-group-item ${page == 'webinfomation' && 'active text-white'}`} onClick={() => {
                                                    setPage('webinfomation')
                                                }}>
                                                ตั้งค่าเว็บไซต์
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-10 col-sm-12">
                                    {
                                        page == 'dashboard' && <>
                                            DashBoard
                                        </>
                                    }
                                    {
                                        page == 'all_products' && <AllProducts {...components} page={page} setPage={setPage} setProductId={setProductId} />
                                    }
                                    {
                                        page == 'product' && <Product {...components} productId={productId} setPage={setPage} />
                                    }
                                    {
                                        page == 'new_prodcut' && <Product {...components} productId={productId} setPage={setPage} />
                                    }
                                    {
                                        page == 'all_categories' && <AllCategories {...components} />
                                    }
                                    {
                                        page == 'banner' && <Banner {...components} />
                                    }
                                    {
                                        page == 'webinfomation' && <WebInfomation {...components} />
                                    }
                                    {
                                        page == 'allblog' && <AllBlogs {...components} setPage={setPage} setBlogId={setBlogId} />
                                    }
                                    {
                                        page == 'editBlog' && <Blog {...components} blogId={blogId} setPage={setPage} />
                                    }
                                    {
                                        page == 'newblog' && <NewBlog {...components} setPage={setPage} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    ) : (
        <Login {...components} />
    )
}

export default AdminHome;