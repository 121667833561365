import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import '../../assets/css/product_page.css'
import axios from "axios";
import Select from 'react-select'
import MoreDetails from "./MoreDetails";
import ProductDetail from "./ProductDetail";
import Cookies from 'universal-cookie';
import Skeleton from "react-loading-skeleton";
import toast, { Toaster } from 'react-hot-toast';
import { marked } from "marked";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import parse from 'html-react-parser';
const cookies = new Cookies(null, { path: '/' });

const Product = (components) => {
    const navigate = useNavigate();
    const notify = (msg, options) => toast(msg, {...options});
    const params = useParams();
    const quantityRef = useRef(null)
    const [productCurrentPriceId, setProductCurrentPriceId] = useState([]);
    const [productDetail, setProductDetail] = useState([]);
    const [productPrice, setProductPrice] = useState([]);
    const [productImg, setProductImg] = useState([]);
    const [productPriceShow, setProductPriceShow] = useState([]);
    const [productPriceNameShow, setProductPriceNameShow] = useState([]);
    const [wishlisted, setWishlisted] = useState(false);
    const [stock, setStock] = useState(0);
    const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/products/id/${params.product_id}`).then((response) => {
            const data = response.data;
            
            if(data.message === 'FOUND')
            {
                setProductDetail(data.product);
                setProductPrice(data.priceOfProduct);
                setProductImg(data.imageOfProduct);

                if(data.lowPrice.id != 0)
                {
                    setProductPriceNameShow(data.lowPrice.name);
                    setProductPriceShow(parseFloat(data.lowPrice.price).toLocaleString('en-US') + "฿");
                    setProductCurrentPriceId(data.lowPrice.id);
                    setStock(data.lowPrice.stock);
                }
                else
                {
                    setProductPriceNameShow('ยังไม่มีให้เลือกชนิด');
                    setProductPriceShow("0.00฿");
                    setProductCurrentPriceId(0);
                    setStock(0);
                }
            }
            else
            {
                navigate('/')
            }
        })

        const hasWishlist = components.wishlist.some((wishlist) => { 
            return wishlist.product_id == params.product_id
        });

        setWishlisted(hasWishlist)
    }, [components.wishlist]);

    const changeCurrentPrice = (price_id) => {
        const find_idx = productPrice.findIndex((price) => {
            return price.value == price_id
        });
        setProductCurrentPriceId(productPrice[find_idx].value);
        setProductPriceShow(parseFloat(productPrice[find_idx].price).toLocaleString('en-US') + "฿");
        setStock(productPrice[find_idx].stock)
        
        setProductPriceNameShow(productPrice[find_idx].name);
    }

    const addProductToCart = async () => {
        if(stock <= 0)
        {
            notify('ตัวเลือกนี้ไม่มีสต็อค', {
                duration: 5000,
                style: {
                    color: "white",
                    backgroundColor: "red"
                },
                icon: '❌',
                position: 'bottom-center',
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
            });
            return;
        }

        if(productCurrentPriceId == 0)
        {
            notify('สินค้านี้ยังไม่มีตัวเลือกให้ซื้อ', {
                duration: 5000,
                style: {
                    color: "white",
                    backgroundColor: "red"
                },
                icon: '❌',
                position: 'bottom-center',
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
            });
            return;
        }

        if(parseInt(quantityRef.current.value) <= 0)
        {
            notify('ไม่สามารถเพิ่มสินค้าลงตะกร้าได้ เนื่องจากคุณใส่จำนวนเป็น 0 หรือน้อยกว่า !', {
                duration: 5000,
                style: {
                    color: "white",
                    backgroundColor: "red"
                },
                icon: '❌',
                position: 'bottom-center',
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
            });
            return;
        }

        if(isNaN(parseInt(quantityRef.current.value)))
        {
            notify('กรุณาใส่จำนวนสินค้าเป็นตัวเลขเท่านั้น !', {
                duration: 5000,
                style: {
                    color: "white",
                    backgroundColor: "red"
                },
                icon: '❌',
                position: 'bottom-center',
                iconTheme: {
                    primary: '#000',
                    secondary: '#fff',
                },
            });
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/products/addtocard`, {
            product_id: productDetail[0].id,
            product_price_id: productCurrentPriceId,
            quantity: parseInt(quantityRef.current.value)
        }, {
            headers: {
                token: cookies.get('token') || null
            }
        }).then((response) => {
            const data = response.data;

            if(data.message == 'success')
            {
                notify("เพิ่มสินค้าเข้าตะกร้าเรียบร้อยแล้ว", {
                    duration: 3000,
                    style: {
                        color: "white",
                        backgroundColor: "#004dda"
                    },
                    icon: '✔️',
                    position: 'bottom-center',
                    iconTheme: {
                        primary: '#000',
                        secondary: '#fff',
                    },
                });
                components.reloadCarts();
            }
            else
            {
                notify(data.message, {
                    duration: 5000,
                    style: {
                        color: "white",
                        backgroundColor: "red"
                    },
                    icon: '❌',
                    position: 'bottom-center',
                    iconTheme: {
                        primary: '#000',
                        secondary: '#fff',
                    },
                });
            }
        })
    }

    return (
        <main>
            <div className="container margin_30">
            <Modal open={open} onClose={onCloseModal} center>
                <h2>รายละเอียดเพิ่มเติม</h2>
                {
                    productDetail.length > 0 && parse(marked.parse(productDetail[0].description))
                }
            </Modal>
                <Toaster />
                <div className="row">
                    <div className="col-md-6">
                    {
                        productImg.length > 0 ? <><ProductDetail productImg={productImg} /></> : <Skeleton count="20"/>
                    }
                    </div>
                    <div className="col-md-6">
                        <div className="prod_info">
                            {
                                productDetail.length > 0 ? (
                                    <h1>{productDetail[0].name}</h1>
                                ) : <Skeleton count="1"/>
                            }
                            
                            {/* <span className="rating">
                            <i className="icon-star voted" />
                            <i className="icon-star voted" />
                            <i className="icon-star voted" />
                            <i className="icon-star voted" />
                            <i className="icon-star" />
                            <em>4 reviews</em>
                            </span> */}
                            <small>
                                {
                                    productDetail.length > 0 ? (
                                        "Model: " + productDetail[0].code
                                    ) : <Skeleton count="2"/>
                                }
                            </small>
                            {/* <p>
                            
                            <br />
                            Sed ex labitur adolescens scriptorem. Te saepe verear tibique sed. Et
                            wisi ridens vix, lorem iudico blandit mel cu. Ex vel sint zril
                            oportere, amet wisi aperiri te cum.
                            </p> */}
                            <div className="prod_options">
                                {/* <div className="row">
                                    <label className="col-xl-5 col-lg-5  col-md-6 col-6 pt-0">
                                    <strong>Color</strong>
                                    </label>
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-6 colors">
                                    <ul>
                                        <li>
                                        <a href="#0" className="color color_1 active" />
                                        </li>
                                        <li>
                                        <a href="#0" className="color color_2" />
                                        </li>
                                        <li>
                                        <a href="#0" className="color color_3" />
                                        </li>
                                        <li>
                                        <a href="#0" className="color color_4" />
                                        </li>
                                    </ul>
                                    </div>
                                </div> */}
                                
                                <div className="row">
                                    <label className="col-xl-5 col-lg-5  col-md-6 col-6">
                                    <strong>จำนวน</strong>
                                    </label>
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-6">
                                        <div className="numbers-row">
                                            <input
                                                type="text"
                                                defaultValue={1}
                                                id="quantity_1"
                                                className="qty2"
                                                name="quantity_1"
                                                ref={quantityRef}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-xl-5 col-lg-5 col-md-6 col-6">
                                        <strong>ตัวเลือก</strong>
                                    </label>
                                    <div className="col-xl-4 col-lg-5 col-md-6 col-6">
                                        {
                                            productPrice.length > 0 ? <Select defaultValue={productPrice[0]} options={productPrice} onChange={(e) => {
                                                changeCurrentPrice(e.value)
                                            }}/> : <Skeleton count="2"/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <div className="price_main">
                                    <span className="new_price">{productPriceShow}</span> (Stock: {stock})
                                    {/* <span className="percentage">-20%</span>{" "} */}
                                    {/* <span className="old_price">$160.00</span> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="btn_add_to_cart">
                                        <button className="btn_1" onClick={addProductToCart}>
                                            {
                                                stock > 0 ? parse(`<i className="ti-shopping-cart" /> เพิ่มเข้าตะกร้า`) : "สินค้าหมด"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                {
                                    !wishlisted ? <>
                                        <button type="button" className="btn_1 red full-width mt-3" onClick={() => {
                                            components.addToWishlist(productDetail[0].id)
                                        }}>
                                            <i className="ti-heart" />
                                            &nbsp;เพิ่มในรายการโปรด
                                        </button>
                                    </> : <>
                                        <button type="button" className="btn_1 gray full-width mt-3" onClick={() => {
                                            components.removeWishlist(productDetail[0].id)
                                        }}>
                                            <i className="ti-heart" />
                                            &nbsp;ลบจากรายการโปรด
                                        </button>
                                    </>
                                }
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <button type="button" className="btn_1 full-width mt-3" onClick={() => {
                                    onOpenModal();
                                }}>
                                    <i className="ti-bookmark-alt" />
                                    &nbsp;ดูรายละเอียดสินค้า
                                </button>
                            </div>
                        </div>
                    {/* /product_actions */}
                    </div>
                </div>
                {/* {
                    productDetail.length > 0 ? <><hr/><MoreDetails description={marked.parse(productDetail[0].description)} /></> : ""
                } */}
                {/* /row */}
            </div>
        </main>
    )
}

export default Product;