import OwlCarousel from 'react-owl-carousel';
import { useEffect, useState } from "react";
// import Skeleton from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'
// import { useMediaQuery } from 'react-responsive'

const Carousel = (components) => {
    const [banner, setBanner] = useState([]);

    useEffect(() => {
        setBanner(components.banner);
    }, [components])

    // const isDesktopOrLaptop = useMediaQuery({
    //     query: '(min-width: 1224px)'
    // });

    const options = {
        items: 1,
        loop: true,
        nav: false,
        dotsClass:"owl-dot",
        dotsClass:"owl-dots",
        dots:true,
        maxHeight: 750,
        responsive:{
              0:{
                   dots: false,
              },
              767:{
                    dots: false,
              },
              768:{
                   dots: true,
              }
        },
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true
    };

    return (
        <>
            {
                banner.length > 0 && <OwlCarousel
                    className='owl-carousel owl-theme'
                    {...options}
                >
                    {
                        banner.map((bn, idx) => {
                            return (
                                <div className='item' key={idx}>
                                    <img src={`${process.env.REACT_APP_API_URL}/banner/get/${bn}`}/>
                                </div>
                            )
                        })
                    }
                </OwlCarousel>
            }
        </>
    )
}

export default Carousel;