import { useEffect, useState } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useNavigate, Link } from 'react-router-dom';

const Header = (components) => {
  const navigate = useNavigate();
  
  const carts = components.carts;
  const totals = components.totals;
  const [allProducts, setAllProducts] = useState([]);
  const [statusToken, setStatusToken] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setAllProducts(components.allProducts)
    setStatusToken(components.statusToken)
    setIsAdmin(components.isAdmin)
  }, [components])
  
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    // console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    // console.log(item)
    navigate(`/goto/${item.id}`);
    // navigate(`/products/${item.id}`);
  }

  const handleOnFocus = () => {
    // console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <Link to={`/goto/${item.id}`} style={{ display: 'block', textAlign: 'left' }}>
          <img style={{
              width: '25px',
              height: '25px',
              borderRadius: '2px'
          }} src={item.path != null ? item.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`} />&nbsp;
          {item.name} <small>Model: {item.code}</small>
        </Link>
      </>
    )
  }

  return (
    <header className="version_1">
        <div className="layer" />
        {/* Mobile menu overlay mask */}
        <div className="main_header shadow">
          <div className="container">
            <div className="row small-gutters">
              <div className="col-xl-3 col-lg-3 d-lg-flex align-items-center">
                <div id="logo">
                  <Link to='/'>
                    <img src={`${process.env.REACT_APP_BASE_URL}/img/logo.png`} alt="" width={100} height={35} />
                  </Link>
                </div>
              </div>
              <nav className="col-xl-6 col-lg-7">
                <a className="open_close">
                  <div className="hamburger hamburger--spin">
                    <div className="hamburger-box">
                      <div className="hamburger-inner" onClick={() => {
                        const element = document.getElementById("main_menu");
                        element.classList.add('show');
                      }}/>
                    </div>
                  </div>
                </a>
                {/* Mobile menu button */}
                <div id="main_menu" className="main-menu" style={{ zIndex: "1000"}}>
                  <div id="header_menu">
                    <a href="/">
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/img/logo_none_text_original.png`}
                        alt=""
                        width={100}
                        height={35}
                      />
                    </a>
                    <a href="#" className="open_close" id="close_in" onClick={() => {
                      const element = document.getElementById("main_menu");
                      element.classList.remove('show');
                    }}>
                      <i className="ti-close" />
                    </a>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">หน้าแรก</Link>
                    </li>
                    {/* <li className="megamenu submenu">
                      <a className="show-submenu-mega">
                        Pages
                      </a>
                      <div className="menu-wrapper">
                        <div className="row small-gutters">
                          <div className="col-lg-3">
                            <h3>Listing grid</h3>
                            <ul>
                              <li>
                                <a href="listing-grid-1-full.html">
                                  Grid Full Width
                                </a>
                              </li>
                              <li>
                                <a href="listing-grid-2-full.html">
                                  Grid Full Width 2
                                </a>
                              </li>
                              <li>
                                <a href="listing-grid-3.html">Grid Boxed</a>
                              </li>
                              <li>
                                <a href="listing-grid-4-sidebar-left.html">
                                  Grid Sidebar Left
                                </a>
                              </li>
                              <li>
                                <a href="listing-grid-5-sidebar-right.html">
                                  Grid Sidebar Right
                                </a>
                              </li>
                              <li>
                                <a href="listing-grid-6-sidebar-left.html">
                                  Grid Sidebar Left 2
                                </a>
                              </li>
                              <li>
                                <a href="listing-grid-7-sidebar-right.html">
                                  Grid Sidebar Right 2
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-3">
                            <h3>Listing row &amp; Product</h3>
                            <ul>
                              <li>
                                <a href="listing-row-1-sidebar-left.html">
                                  Row Sidebar Left
                                </a>
                              </li>
                              <li>
                                <a href="listing-row-2-sidebar-right.html">
                                  Row Sidebar Right
                                </a>
                              </li>
                              <li>
                                <a href="listing-row-3-sidebar-left.html">
                                  Row Sidebar Left 2
                                </a>
                              </li>
                              <li>
                                <a href="listing-row-4-sidebar-extended.html">
                                  Row Sidebar Extended
                                </a>
                              </li>
                              <li>
                                <a href="product-detail-1.html">
                                  Product Large Image
                                </a>
                              </li>
                              <li>
                                <a href="product-detail-2.html">Product Carousel</a>
                              </li>
                              <li>
                                <a href="product-detail-3.html">
                                  Product Sticky Info
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-3">
                            <h3>Other pages</h3>
                            <ul>
                              <li>
                                <a href="/carts">Cart Page</a>
                              </li>
                              <li>
                                <a href="checkout.html">Check Out Page</a>
                              </li>
                              <li>
                                <a href="confirm.html">Confirm Purchase Page</a>
                              </li>
                              <li>
                                <a href="account.html">Create Account Page</a>
                              </li>
                              <li>
                                <a href="track-order.html">Track Order</a>
                              </li>
                              <li>
                                <a href="help.html">Help Page</a>
                              </li>
                              <li>
                                <a href="help-2.html">Help Page 2</a>
                              </li>
                              <li>
                                <a href="leave-review.html">Leave a Review</a>
                              </li>
                            </ul>
                          </div>
                          <div className="col-lg-3 d-xl-block d-lg-block d-md-none d-sm-none d-none">
                            <div className="banner_menu">
                              <a href="#0">
                                <img
                                  src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
                                  data-src="img/banner_menu.jpg"
                                  width={400}
                                  height={550}
                                  alt=""
                                  className="img-fluid lazy"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li> */}
                    <li>
                      <Link to="/products">สินค้าทั้งหมด</Link>
                    </li>
                    <li>
                      <Link to="/blogs">บทความ</Link>
                    </li>
                    <li>
                      <Link>ติดต่อเรา</Link>
                    </li>
                    <li className="submenu">
                      <a href="javascript:void(0);" className="show-submenu">
                        ช่วยเหลือ
                      </a>
                      <ul>
                        <li>
                          <Link>
                            อัตราค่าขนส่ง
                          </Link>
                        </li>
                        <li>
                          <Link>วิธีการสั่งซื้อ</Link>
                        </li>
                        <li>
                          <Link>
                            วิธีการชำระเงิน
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {
                      isAdmin && <li>
                        <Link to="/admin">หลังบ้าน</Link>
                      </li>
                    }
                  </ul>
                </div>
                {/*/main-menu */}
              </nav>
              {/* <div className="col-xl-3 col-lg-2 d-lg-flex align-items-center justify-content-end text-end">
                <a className="phone_top" href="tel://020156262">
                  <strong>
                    <span>โทรด่วน !</span>02-015-6262
                  </strong>
                </a>
              </div> */}
            </div>
            {/* /row */}
          </div>
        </div>
        {/* /main_header */}
        <div className="main_nav Sticky">
          <div className="container">
            <div className="row small-gutters">
              <div className="col-xl-3 col-lg-3 col-md-3">
                {/* <nav className="categories">
                  <ul className="clearfix">
                    <li>
                      <span>
                        <a href="#">
                          <span className="hamburger hamburger--spin">
                            <span className="hamburger-box">
                              <span className="hamburger-inner" />
                            </span>
                          </span>
                          MENU
                        </a>
                      </span>
                      <div id="menu">
                        <ul>
                          <li>
                            <span>
                              <a href="#">Categories</a>
                            </span>
                            <ul>
                              {
                                listCategories.map((category, idx) => {
                                  return (
                                    <li key={idx}>
                                      <a href="#">{category.name}</a>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </nav> */}
              </div>
              <div className="col-xl-6 col-lg-7 col-md-6 d-none d-md-block">
                {/* <div className="custom-search-input">
                  <input type="text" placeholder="Search over 10.000 products" />
                  <button type="submit">
                    <i className="header-icon_search_custom" />
                  </button>
                </div> */}
                <div className="custom-search-input">
                  <ReactSearchAutocomplete
                    items={allProducts}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    fuseOptions={{ keys: ["name", "code"] }}
                    // resultStringKeyName="code"
                    // autoFocus
                    styling={{"width":"100%","height":"40px","backgroundColor":"#fff","padding":"15px"}}
                    formatResult={formatResult}
                    placeholder="ค้นหาสินค้าของเราง่ายๆ ได้เลยที่นี่..."
                    maxResults={10}
                    showNoResultsText="ไม่พบสินค้าที่คุณค้นหา"
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-2 col-md-3">
              <ul className="top_tools">
                <li>
                  <div className="dropdown dropdown-cart">
                    <Link to="/carts" className="cart_bt">
                        <strong>{parseFloat(carts.length).toLocaleString("en-US")}</strong>
                    </Link>
                    <div className="dropdown-menu">
                      <ul>
                          <ul>
                          {
                            carts.length > 0 ? carts.map((cart, index) => {
                              return (
                                <li key={index}>
                                    <Link to={`/goto/${cart.product_id}`}>
                                        <figure>
                                            <img
                                                src={cart.product_image != null ? cart.product_image.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                data-src={cart.product_image != null ? cart.product_image.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/products/shoes/1.jpg`}
                                                alt=""
                                                width={50}
                                                height={50}
                                                className="lazy loaded"
                                                data-was-processed="true"
                                            />
                                        </figure>
                                        <strong>
                                            <span>
                                              <b>{cart.product_name}</b> x{cart.quantity}
                                            </span>
                                            <span>
                                              ตัวเลือก: {cart.product_price_name}
                                            </span>
                                            <p>
                                              {parseFloat(cart.price_per_each * cart.quantity).toLocaleString("en-US")}฿
                                            </p>
                                        </strong>
                                    </Link>
                                    <a href="javascript:void(0);" className="action" onClick={() => {
                                      components.removeProductInCart(cart.product_id, cart.product_price_id)
                                    }}>
                                        <i className="ti-trash" />
                                    </a>
                                </li>
                              )
                            }) : "ไม่มีสินค้าในตะกร้า..."
                          }
                          </ul>
                      </ul>
                      {
                        carts.length > 0 ? <div className="total_drop">
                            <div className="clearfix">
                              <strong>ยอดรวมสุทธิ</strong>
                              <span>
                                {totals.toLocaleString("en-US")}฿
                              </span>
                            </div>
                            <Link to="/carts" className="btn_1 outline">
                              ดูสินค้าในตะกร้าของคุณ
                            </Link>
                            <a href="checkout.html" className="btn_1">
                              ชำระเงิน
                            </a>
                        </div> : ""
                      }
                    </div>
                  </div>
                    
                </li>
                <li>
                  <Link to={`/wishlist`} className="wishlist">
                      <span>Wishlist</span>
                  </Link>
                </li>
                <li>
                <div className="dropdown dropdown-access">
                    <Link to="/account" className="access_link">
                      <span>Account</span>
                    </Link>
                    <div className="dropdown-menu">
                      <ul>
                            <li>
                              {
                                <Link to={`/account`}>
                                  <i className="ti-user" />
                                  {
                                    statusToken ? 'บัญชีของฉัน' : 'เข้าสู่ระบบ / สมัครสมาชิก'
                                  }
                                </Link>
                              }
                            </li>
                            {
                              statusToken && <li>
                                <Link to={`/logout`} style={{
                                  color: 'red !important'
                                }}>
                                  <i className="ti-power-off" />
                                  ออกจากระบบ
                                </Link>
                              </li>
                            }
                      </ul>
                    </div>
                </div>
                {/* /dropdown-access*/}
                </li>
                <li>
                <Link className="btn_search_mob">
                    <span>Search</span>
                </Link>
                </li>
                <li>
                {/* <a href="#menu" className="btn_cat_mob">
                    <div className="hamburger hamburger--spin" id="hamburger">
                    <div className="hamburger-box">
                        <div className="hamburger-inner" />
                    </div>
                    </div>
                    Categories
                </a> */}
                </li>
            </ul>
              </div>
            </div>
            {/* /row */}
          </div>
          <div className="search_mob_wp">
            <ReactSearchAutocomplete
              items={allProducts}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              fuseOptions={{ keys: ["name", "code"] }}
              // resultStringKeyName="code"
              // autoFocus
              styling={{"width":"100%","height":"40px","backgroundColor":"#fff","padding":"15px"}}
              formatResult={formatResult}
              placeholder="ค้นหาสินค้าของเราง่ายๆ ได้เลยที่นี่..."
              maxResults={10}
              showNoResultsText="ไม่พบสินค้าที่คุณค้นหา"
            />
          </div>
          {/* /search_mobile */}
        </div>
        {/* /main_nav */}
      </header>
  );
}

export default Header;