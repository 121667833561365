import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { marked } from "marked";

const AllBlogs = (components) => {
    const [allBlogs, setAllBlogs] = useState(components.allBlogs);

    useEffect(() => {
        setAllBlogs(components.allBlogs);
    }, [components.allBlogs]);

    const removeTagHTML = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/(<([^>]+)>)/ig, '');
    }

    return (
        <div className="row mt-3">
            {
                allBlogs.length > 0 ? allBlogs.map((blog, index) => {
                    return (
                        <div className="col-md-6" key={index}>
                            <article className="blog" style={{height: "450px", maxHeight: "450px"}}>
                                <figure>
                                    <Link onClick={() => {
                                            components.setPage('editBlog');
                                            components.setBlogId(blog.id);
                                        }}>
                                        <img src={blog.path != "" ? blog.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/blog-thumb-1.jpg`} alt="" />
                                        <div className="preview">
                                            <span>ดูเพิ่มเติม</span>
                                        </div>
                                    </Link>
                                </figure>
                                <div className="post_info">
                                    <small>เขียนเมื่อ {blog.createdAt}</small>
                                    <h2>
                                        <Link onClick={() => {
                                            components.setPage('editBlog');
                                            components.setBlogId(blog.id);
                                        }}>
                                            {blog.title}
                                        </Link>
                                    </h2>
                                    <p>
                                        {
                                            removeTagHTML(marked.parse(blog.html)).length > 200 ? removeTagHTML(marked.parse(blog.html)).slice(0, 200) + "..." : removeTagHTML(marked.parse(blog.html))
                                        }
                                    </p>
                                    <p>
                                        <Link onClick={() => {
                                            components.setPage('editBlog');
                                            components.setBlogId(blog.id);
                                        }}>
                                            แก้ไข
                                        </Link>
                                    </p>
                                </div>
                            </article>
                        </div>
                    )
                }) : <Skeleton count={50} />
            }
        </div>
    )
}

export default AllBlogs;