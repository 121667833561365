import { useEffect, useState, useRef } from "react";
import axios from "axios";
import SimpleMDE from "react-simplemde-editor";
import Cookies from "universal-cookie";
import Swal from 'sweetalert2'

const Blog = (components) => {
    const cookies = new Cookies(null, { path: '/' });
    
    const [blog, setBlog] = useState({});
    const [blogImage, setBlogImage] = useState("");
    const [blogHTML, setBlogHTML] = useState("");
    const titleRef = useRef(null);
    const fileRef = useRef(null);

    const onChangeBlogHTML = (value) => {
        setBlogHTML(value);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/blogs/get/${components.blogId}`).then((response) => {
            if(response.data.message == 'FOUND')
            {
                setBlog(response.data.blog);
                setBlogHTML(response.data.blog.html);
                setBlogImage(response.data.blog.path);
            }
        });
    }, [components]);

    const submitChangeBlog = (event) => {
        event.preventDefault();
        const title = titleRef.current.value;
        const html = blogHTML;

        if(title == undefined || title == '')
        {
            components.notify_error('หัวเรื่อง ไม่สามารถเว้นว่างได้');
            return;
        }

        axios.post(`${process.env.REACT_APP_API_URL}/blogs/update`, {
            id: blog.id,
            title: title,
            html: html
        }, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                components.notify_success('อัพเดท Blog เรียบร้อยแล้ว');
                components.reloadBlogs();
            }
            else
            {
                components.notify_error(`อัพเดท Blog ไม่สำเร็จ (${response.data.message})`);
            }
        });
    }

    const uploadImageBlog = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        
        axios.post(`${process.env.REACT_APP_API_URL}/upload/blogImg`, formData, {
            headers: {
                token: cookies.get('token')
            }
        }).then((response) => {
            if(response.data.message == 'OK')
            {
                const image_path = response.data.path;
                axios.post(`${process.env.REACT_APP_API_URL}/blogs/updateImg`, {
                    id: blog.id,
                    path: response.data.path
                }, {
                    headers: {
                        token: cookies.get('token')
                    }
                }).then((response) => {
                    if(response.data.message == 'OK')
                    {
                        setBlogImage(image_path);
                        components.notify_success('อัพโหลดรูปภาพเรียบร้อยแล้ว');
                    }
                    else
                    {
                        components.notify_error(`อัพเดทรูปภาพไม่สำเร็จ (${response.data.message})`)
                    }
                })
            }
            else
            {
                components.notify_error(`อัพโหลดรูปภาพไม่สำเร็จ (${response.data.message})`)
            }
        })
    }

    const deleteBlog = () => {
        Swal.fire({
            title: "ยืนยันหรือไม่ ?",
            text: "แน่ใจที่จะลบ Blog ใช่หรือไม่",
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "ใช่, ลบเลย!",
            cancelButtonText: "ไม่, ยกเลิก!",
            dangerMode: true,
        }).then(function(result) {
            if(result.isConfirmed)
            {
                axios.post(`${process.env.REACT_APP_API_URL}/blogs/delete`, {
                    id: blog.id
                }, {
                    headers: {
                        token: cookies.get('token')
                    }
                }).then((response) => {
                    if(response.data.message == 'OK')
                    {
                        components.notify_success('ลบ Blog เรียบร้อยแล้ว');
                        components.reloadBlogs();
                        components.setPage('allblog');
                    }
                    else
                    {
                        components.notify_error(`เกิดข้อผิดพลาดในการลบ Blog ${response.data.message}`);
                    }
                });
            }
        })
    }

    return (
        <form onSubmit={submitChangeBlog}>
            <div className="row">
                <div className="col-12">
                    <img src={blogImage != "" ? blogImage.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/blog-thumb-1.jpg`} class="rounded mx-auto d-block" style={
                        {
                            maxWidth: "200px",
                            maxHeight: "133px"
                        }
                    } />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-3">
                    <input name="file_img" id="file_img" ref={fileRef} type="file" className="form-control"/>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-3">
                    <button type="button" className="btn_1 full-width" onClick={() => {
                        uploadImageBlog(fileRef.current.files[0]);
                    }}>
                        อัพเดทรูปภาพ
                    </button>
                </div>
                <div className="col-12 my-3">
                    <label for="blog_title">หัวเรื่อง:</label>
                    <input name="blog_title" id="blog_title" type="text" className="form-control" placeholder="หัวเรื่อง" defaultValue={blog.title} ref={titleRef}/>
                </div>
                <div className="col-12 mb-3">
                    <label>BLOG:</label>
                    <SimpleMDE
                        value={blogHTML}
                        onChange={onChangeBlogHTML}
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <button type="submit" className="btn_1 full-width">
                        <i className="ti-pencil"></i> แก้ไข Blog
                    </button>
                </div>
                <div className="col-md-6 col-sm-12">
                    <button type="button" className="btn_1 red full-width" onClick={deleteBlog}>
                        <i className="ti-trash"></i> ลบ Blog
                    </button>
                </div>
            </div>
        </form>
    )
}

export default Blog;