import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { marked } from "marked";

const HomeBlogs = (components) => {
    const [allBlogs, setAllBlogs] = useState(components.allBlogs);

    useEffect(() => {
        setAllBlogs(components.allBlogs);
    }, [components]);

    const removeTagHTML = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        return str.replace(/(<([^>]+)>)/ig, '');
    }

    return (
        <div style={{ backgroundColor: "rgb(238, 238, 238)" }}>
            <div className="container margin_60_35">
            <div className="main_title">
                <h2>บทความใหม่</h2>
                <span>บทความ</span>
                <p>
                    ติดตามบทความดีๆของเราได้ที่นี่
                </p>
            </div>
            <div className="row">
                {
                    allBlogs.length > 0 ? allBlogs.slice(0, 4).map((blog, index) => {
                        return (
                            <div className="col-lg-6" key={index}>
                                <Link to={`/blog/${blog.id}`} className="box_news">
                                    <figure>
                                        <img
                                            src={blog.path != "" ? blog.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/blog-thumb-1.jpg`}
                                            data-src={blog.path != "" ? blog.path.replace(`{api_path}`, process.env.REACT_APP_API_URL) : `${process.env.REACT_APP_BASE_URL}/img/blog-thumb-1.jpg`}
                                            alt={blog.title}
                                            width={400}
                                            height={266}
                                            className="lazy"
                                        />
                                        <figcaption>
                                            <strong>{blog.day}</strong>{blog.month}
                                        </figcaption>
                                    </figure>
                                    <ul>
                                        <li>เขียนเมื่อ</li>
                                        <li>{blog.createdAt}</li>
                                    </ul>
                                    <h4>
                                        {blog.title}
                                    </h4>
                                    <p>
                                        {
                                            removeTagHTML(marked.parse(blog.html)).length > 200 ? removeTagHTML(marked.parse(blog.html)).slice(0, 200) + "..." : removeTagHTML(marked.parse(blog.html))
                                        }
                                    </p>
                                </Link>
                            </div>
                        )
                    })
                    : <Skeleton count={10} />
                }
                <div className="text-center">
                    <Link to="/blogs" className="btn_1">
                        ดูบทความทั้งหมด...
                    </Link>
                </div>
            </div>
        </div>
        </div>
        
    )
}

export default HomeBlogs;