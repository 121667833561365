import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const Logout = (components) => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: '/' });

    useEffect(() => {
        components.setStatusToken(false);
        cookies.remove('token');
        navigate('/');
        window.location.reload(false);
    }, [])
    
    return (
        <main>
            <div className="container">
                <div className="row">
                    <div className='col-12 text-center'>
                        กำลังออกจากระบบ...
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Logout;